import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { base64UrlEncode } from '../../utils';

@Injectable({ providedIn: 'root' })
export class OAuthServicePatch extends OAuthService {
  async createAndSaveCodeChallenge(): Promise<string> {
    const [challenge, verifier] = await this.createChallangeVerifierPairForPKCE();

    this._storage.setItem('PKCE_verifier', verifier);

    return challenge;
  }

  override createAndSaveNonce(): Promise<string> {
    const savedNonce = this._storage.getItem('nonce');

    if (savedNonce) {
      return Promise.resolve(savedNonce);
    }

    return super.createAndSaveNonce();
  }

  protected override async createChallangeVerifierPairForPKCE(): Promise<[string, string]> {
    if (!this.crypto) {
      throw new Error('PKCE support for code flow needs a CryptoHander.');
    }

    let verifier = this._storage.getItem('PKCE_verifier');

    if (!verifier) {
      verifier = await this.createNonce();
    }

    const challengeRaw = await this.crypto.calcHash(verifier, 'sha-256');
    const challenge = base64UrlEncode(challengeRaw);

    return [
      challenge,
      verifier,
    ];
  }

  protected override restartRefreshTimerIfStillLoggedIn(): void {
    this.clearAccessTokenTimer();
    this.clearIdTokenTimer();
    this.setupExpirationTimers();
  }

  protected override setupExpirationTimers(): void {
    if (this.hasValidAccessToken()) {
      this.setupAccessTokenTimer();
    }

    if (this.hasValidIdToken()) {
      this.setupIdTokenTimer();
    }
  }
}
