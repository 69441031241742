import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { ConfirmDialog, ConfirmDialogConfig } from '@ideals/models';

@Injectable({ providedIn: 'root' })
export class ConfirmDialogService implements ConfirmDialog {
  #confirmationService!: ConfirmationService;

  openConfirm(config: ConfirmDialogConfig): void {
    this.#checkConfirmationService();
    this.#confirmationService.confirm(config);
  }

  setConfirmationService(service: ConfirmationService): void {
    if (this.#confirmationService) {
      throw new Error('ConfirmationService is already set');
    }
    this.#confirmationService = service;
  }

  #checkConfirmationService(): void {
    if (!this.#confirmationService) {
      throw new Error('ConfirmationService is not set. Please, provide it using "setConfirmationService"');
    }
  }
}
