import { BreakpointObserver } from '@angular/cdk/layout';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { debounceTime, distinctUntilChanged, fromEvent, map, startWith } from 'rxjs';

interface CustomWindow extends Window {
  readonly flutter_inappwebview: null;
  readonly webkit: null;
}

export type PlatformType = 'desktop' | 'tablet' | 'mobile';

const SCREEN_RESIZE_DEBOUNCE_TIME = 100;

@Injectable({ providedIn: 'root' })
export class PlatformService {
  readonly #breakpointObserver = inject(BreakpointObserver);
  readonly #deviceDetectorService = inject(DeviceDetectorService);

  readonly #platformType$ = fromEvent(window, 'resize').pipe(
    debounceTime(SCREEN_RESIZE_DEBOUNCE_TIME),
    map(() => this.#platformType),
    distinctUntilChanged(),
    startWith(this.#platformType),
  );

  readonly isAndroid = signal(this.#deviceDetectorService.getDeviceInfo().os === 'Android');
  readonly isDesktop = computed(() => this.platformType() === 'desktop');
  readonly isIOS = signal(this.#deviceDetectorService.getDeviceInfo().os === 'iOS');
  readonly isMobile = computed(() => this.platformType() === 'mobile');
  readonly isSafari = signal(this.#deviceDetectorService.getDeviceInfo().browser === 'Safari');
  readonly isStandalone = signal(this.#isStandalone);
  readonly isTablet = computed(() => this.platformType() === 'tablet');
  readonly isWindows = signal(this.#deviceDetectorService.getDeviceInfo().os === 'Windows');
  readonly platformType = toSignal(this.#platformType$, { requireSync: true });
  readonly platformType$ = this.#platformType$;

  get deviceInfo(): DeviceInfo {
    return this.#deviceDetectorService.getDeviceInfo();
  }

  get #isStandalone(): boolean {
    const win = window as CustomWindow & typeof globalThis;

    return win.webkit != null || win.flutter_inappwebview != null;
  }

  get #platformType(): PlatformType {
    const isMobile = this.#deviceDetectorService.isMobile() || this.#breakpointObserver.isMatched('(max-width: 768px)');
    const isTablet = !isMobile && (this.#deviceDetectorService.isTablet() || this.#breakpointObserver.isMatched('(max-width: 1200px)'));

    if (isMobile || this.#isStandalone) {
      return 'mobile';
    }

    if (isTablet) {
      return 'tablet';
    }

    return 'desktop';
  }

  initialize(): void {
    const html = document.querySelector('html')!;

    this.#platformType$.subscribe((platformType) => {
      html.classList.remove('ideals-desktop', 'ideals-tablet', 'ideals-mobile');
      html.classList.add(`ideals-${platformType}`);
    });

    if (this.#isStandalone) {
      html.classList.add('ideals-standalone');
    }
  }
}
