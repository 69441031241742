import {
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDayNames,
  getLocaleFirstDayOfWeek,
  getLocaleMonthNames,
  registerLocaleData,
  TranslationWidth,
} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, LOCALE_ID, Provider } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocaleData, registerLocale } from 'i18n-iso-countries';
import { CookieService } from 'ngx-cookie-service';
import { PrimeNGConfig } from 'primeng/api';
import { catchError, map, Observable, of } from 'rxjs';

import { Culture } from './models';
import { adjustLocaleDateFormatToPrimeNg } from './utils/adjust-locale-date-format-to-prime-ng';

export class LocalizeTranslateLoader implements TranslateLoader {
  readonly #httpClient = inject(HttpClient);

  getTranslation<T = Record<string, string>>(lang: string): Observable<T> {
    const localizationUrl = `/localization/${lang}.json?${Date.now()}`;

    return this.#httpClient.get<T>(localizationUrl).pipe(
      map((originalTranslations) => {
        const translations: T = {} as T;

        for (const key in originalTranslations) {
          if (originalTranslations[key]) {
            translations[key] = originalTranslations[key];
          }
        }

        return translations;
      }),
      catchError(() => of({} as T))
    );
  }
}

export class LocaleId extends String {
  readonly #translateService = inject(TranslateService);

  override toString(): string {
    return this.#translateService.currentLang;
  }

  override valueOf(): string {
    return this.#translateService.currentLang;
  }
}

export function provideLocalization(): (Provider | EnvironmentProviders)[] {
  return [
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: Culture.US,
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useClass: LocalizeTranslateLoader,
      },
      useDefaultLang: true,
    })),
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => {
        const primeNGConfig = inject(PrimeNGConfig);
        const translateService = inject(TranslateService);
        const cookieService = inject(CookieService);
        const browserLang = translateService.getBrowserLang();
        const culture = (cookieService.get('culture') || Object.values(Culture).find((lang) => lang.includes(browserLang!)))
          ?? Culture.US;

        translateService.onLangChange.subscribe(({ lang }) => {
          const [cultureId] = lang.split('-');

          import(
            /* webpackExclude: /\.d\.ts$/ */
            /* webpackInclude: /(de|en|es|fr|it|nl|pl|pt|sv|tr|ko|ru|uk|zh|ja)\.mjs$/ */
            /* webpackMode: "lazy-once" */
            /* webpackChunkName: "i18n-locales" */
            `./../../../../node_modules/@angular/common/locales/${cultureId}.mjs`
          ).then(({ default: locale }) => {
            registerLocaleData(locale, lang);

            primeNGConfig.setTranslation({
              dateFormat: adjustLocaleDateFormatToPrimeNg(getLocaleDateFormat(lang, FormatWidth.Medium)),
              dayNames: getLocaleDayNames(lang, FormStyle.Standalone, TranslationWidth.Wide) as string[],
              dayNamesMin: getLocaleDayNames(lang, FormStyle.Standalone, TranslationWidth.Short) as string[],
              dayNamesShort: getLocaleDayNames(lang, FormStyle.Standalone, TranslationWidth.Abbreviated) as string[],
              emptyFilterMessage: translateService.instant('common.TEXT.no_matching_results') as string,
              emptyMessage: translateService.instant('common.TEXT.no_matching_results') as string,
              firstDayOfWeek: getLocaleFirstDayOfWeek(lang),
              monthNames: getLocaleMonthNames(lang, FormStyle.Standalone, TranslationWidth.Wide) as string[],
              monthNamesShort: getLocaleMonthNames(lang, FormStyle.Format, TranslationWidth.Abbreviated) as string[],
            });
          });

          import(
            /* webpackInclude: /(de|en|es|fr|it|nl|pl|pt|sv|tr|ko|ru|uk|zh|ja)\.json/ */
            /* webpackMode: "lazy-once" */
            /* webpackChunkName: "i18n-iso-countries" */
            `./../../../../node_modules/i18n-iso-countries/langs/${cultureId}.json`
          ).then((data: LocaleData) => {
            registerLocale(data);
          });
        });

        return () => translateService.use(culture);
      },
    },
    {
      deps: [TranslateService],
      provide: LOCALE_ID,
      useClass: LocaleId,
    },
  ];
}
