import { Provider } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, RouteReuseStrategy } from '@angular/router';

class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig?.component === curr.routeConfig?.component;
  }
}

export function provideRouteReuseStrategy(): Provider[] {
  return [{
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStrategy,
  }];
}
