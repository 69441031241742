import { ChangeDetectorRef, effect, inject, Pipe, PipeTransform } from '@angular/core';

import { ThemesService } from './themes.service';

@Pipe({
  name: 'idealsThemeClass',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false,
  standalone: true,
})
export class ThemeClassPipe implements PipeTransform {
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #themesService = inject(ThemesService);

  constructor() {
    effect(() => {
      this.#themesService.dark();
      this.#changeDetectorRef.markForCheck();
    });
  }

  transform(color: string): string {
    const themeName = this.#themesService.colorToThemeName(color);

    // should be same as in themes/_index.scss:12
    return `ideals-${themeName}-${this.#themesService.dark() ? 'dark' : 'light'}-theme`;
  }
}
