import { APP_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { ROUTES } from '@angular/router';
import { OAuthResourceServerErrorHandler, OAuthService, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';

import { AuthConfig } from './models';
import { ServerErrorHandler } from './server-error-handler';
import { AuthService } from './services/auth';
import { OAuthServicePatch } from './services/auth-patch';

import { AUTH_CONFIG_TOKEN } from './auth-config.token';
import { authRoutes } from './auth.routes';

export function provideAuth(authConfig: AuthConfig): (Provider | EnvironmentProviders)[] {
  return [
    provideOAuthClient({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
    {
      multi: true,
      provide: ROUTES,
      useValue: authRoutes,
    },
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => {
        const authService = inject(AuthService);

        return () => authService.initialize();
      },
    },
    {
      provide: AUTH_CONFIG_TOKEN,
      useValue: authConfig,
    },
    {
      provide: OAuthService,
      useClass: OAuthServicePatch,
    },
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: ServerErrorHandler,
    },
    {
      provide: OAuthStorage,
      useFactory: () => window.localStorage,
    },
  ];
}
