import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, isObservable, map, Observable, of, ReplaySubject, switchMap } from 'rxjs';

import { Breadcrumb, Breadcrumbs } from '@ideals/models';

export const BREADCRUMBS_KEY = 'breadcrumbs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
  readonly #breadcrumbs$ = new ReplaySubject<Breadcrumb[]>(1);
  readonly #router = inject(Router);

  #initialized = false;

  readonly breadcrumbs = toSignal(this.#breadcrumbs$);
  readonly breadcrumbs$ = this.#breadcrumbs$.asObservable();

  initialize(): void {
    if (this.#initialized) {
      throw new Error('BreadcrumbsService already initialized');
    }

    this.#initialized = true;

    this.#router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() => this.getBreadcrumbs()),
      )
      .subscribe((breadcrumbs) => {
        this.setBreadcrumbs(breadcrumbs);
      });
  }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.#breadcrumbs$.next(breadcrumbs);
  }

  protected getBreadcrumbs(): Observable<Breadcrumb[]> {
    const result: Observable<Breadcrumb[]>[] = [];

    let route = this.#router.routerState.snapshot.root;

    while (route) {
      const breadcrumbs = route.data[BREADCRUMBS_KEY] as Breadcrumbs ?? [];

      if (isObservable(breadcrumbs)) {
        result.push(breadcrumbs);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        result.push(of(breadcrumbs.map((breadcrumb) => {
          if (breadcrumbs.length > 1 && !breadcrumb.route) {
            throw new Error('Provide route, when more than 1 breadcrumb in array');
          }

          return {
            ...breadcrumb,
            route: breadcrumb.route ?? [this.getRouteUrl(route)],
          };
        })));
      }

      route = route.firstChild!;
    }

    return combineLatest(result).pipe(
      map((breadcrumbsList) => breadcrumbsList.flat()),
    );
  }

  protected getRouteUrl(route: ActivatedRouteSnapshot): string {
    const urlTree = createUrlTreeFromSnapshot(route, ['.']);

    return this.#router.serializeUrl(urlTree);
  }
}
