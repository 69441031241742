export type BillingNotificationType = 'ContractExpires' | 'OutstandingBalance';

export interface BillingNotification {
  readonly description: string;
  readonly icon?: string;
  readonly severity: 'info' | 'warn';
  readonly text: string;
  readonly type: BillingNotificationType;
}

export const HIDE_CONTRACT_EXPIRES_NOTIFICATION_KEY = 'vdr-hide-contract-expires-notification';

export const HIDE_OUTSTANDING_BALANCE_NOTIFICATION_KEY = 'vdr-hide-outstanding-balance-notification';

export const HIDE_BILLING_NOTIFICATION_KEYS: Record<BillingNotificationType, string> = {
  ContractExpires: HIDE_CONTRACT_EXPIRES_NOTIFICATION_KEY,
  OutstandingBalance: HIDE_OUTSTANDING_BALANCE_NOTIFICATION_KEY,
};
