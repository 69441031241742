import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

import { ToastService } from '@ideals/components/toast';

import { HttpError, RequestError } from '../../models';

import { errorsActions } from './errors.actions';

@Injectable()
export class ErrorsEffects {
  readonly #actions$ = inject(Actions);
  readonly #toastService = inject(ToastService);
  readonly #translateService = inject(TranslateService);

  readonly requestError$ = createEffect(() => this.#actions$.pipe(
    ofType(errorsActions.requestError),
    tap(({ error }) => {
      this.#showError(error);
    }),
  ), { dispatch: false });

  #showError(err: HttpError | RequestError): void {
    const defaultMessage = 'common.Error.Something_went_wrong';
    const error = err as HttpError & RequestError;
    let message = '';

    switch (error.status) {
      case 'No Response': {
        message = 'common.ERROR.no_internet';
        break;
      }
      case 'Bad Request': {
        if (error.type === 'Detailed' || error.errors) {
          return;
        }

        message = error.message ?? `${error.detail}(${error.errorCode})`;
        break;
      }
      case 'Unauthorized': {
        return;
      }
      case 'Forbidden': {
        if (error.error) {
          return;
        }

        message = 'common.ERROR.insufficient_permissions';
        break;
      }
      case 'Not Found': {
        message = error.method === 'GET' ? 'common.ERROR.page_not_found' : defaultMessage;
        break;
      }
      default: {
        message = defaultMessage;
      }
    }

    this.#toastService.error(this.#translateService.instant(message) as string);
  }
}
