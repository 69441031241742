import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { OAuthEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { firstValueFrom, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { AUTH_CONFIG_TOKEN } from '../../auth-config.token';
import { AuthQueryParam, AuthRoute, AuthUserRole, Geolocation, OAuthEvents } from '../../models';
import { normalizeUrl, getProjectNameFromUrl } from '../../utils';

export const BANNED_COUNTRY_CODES = ['BY', 'RU'];

const REDIRECT_TIMEOUT = 5000;

interface User {
  readonly email: string;
  readonly idp: string;
  readonly isInternalLogin: boolean;
  readonly roles: AuthUserRole[];
  readonly roomAccess: string[];
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly #appBaseHref = inject(APP_BASE_HREF);
  readonly #authConfig = inject(AUTH_CONFIG_TOKEN);
  readonly #geolocation = signal({} as Geolocation);
  readonly #httpClient = inject(HttpClient);
  readonly #location = window.location;
  readonly #oAuthService = inject(OAuthService);

  readonly geolocation = computed(() => this.#geolocation());
  readonly inBannedLocation = computed(() => BANNED_COUNTRY_CODES.includes(this.#geolocation().countryCode));

  get accessToken(): string {
    return this.#oAuthService.getAccessToken();
  }

  get events$(): Observable<OAuthEvent> {
    return this.#oAuthService.events;
  }

  get isExternalIdp(): boolean {
    return this.user.idp !== 'local';
  }

  get isLoggedIn(): boolean {
    return this.#oAuthService.hasValidAccessToken();
  }

  get state(): string {
    return this.#oAuthService.state as string;
  }

  get user(): User {
    const claims = this.#oAuthService.getIdentityClaims() ?? {};
    const role = claims['role'] as AuthUserRole | AuthUserRole[] ?? [];
    const roomAccess = claims['room_access'] as string | string[] ?? [];

    return {
      email: claims['sub'] as string,
      idp: claims['idp'] as string,
      isInternalLogin: claims['internal_login'] as boolean,
      roles: Array.isArray(role) ? role : [role],
      roomAccess: Array.isArray(roomAccess) ? roomAccess : [roomAccess],
    };
  }

  createAndSaveNonce(): Promise<string> {
    return this.#oAuthService.createAndSaveNonce();
  }

  hasRole(role: AuthUserRole): boolean {
    return this.user.roles.includes(role);
  }

  initCodeByEmailFlow(projectName: string, targetUrl = this.#location.href): void {
    // eslint-disable-next-line camelcase
    this.#oAuthService.initCodeFlow(targetUrl, { room_name: projectName, acr_values: 'check_email' });
  }

  initialize(): Promise<void> {
    this.#oAuthService.events.subscribe(({ type }) => {
      switch (type) {
        case OAuthEvents.InvalidNonce:
          this.initLogin(this.#oAuthService.state);
          break;
        case OAuthEvents.TokenRefreshError:
          this.silentLogout(this.#location.href);
          break;
        case OAuthEvents.SessionError:
          setTimeout(() => {
            this.initLogin(this.#location.href);
          }, REDIRECT_TIMEOUT);
          break;
        default:
          break;
      }
    });

    return this.runInitialLoginSequence()
      .then(() => this.#loadGeolocation())
      .then((value) => {
        this.#geolocation.set(value);
      })
      .catch((error) => {
        console.error('Unhandled error', error);

        return Promise.reject(error);
      });
  }

  initLogin(targetUrl = this.#location.href, params = {}): void {
    const projectName = getProjectNameFromUrl(targetUrl);

    // eslint-disable-next-line camelcase
    this.#oAuthService.initCodeFlow(targetUrl, projectName ? { ...params, room_name: projectName } : params);
  }

  logout(targetUrl = this.#location.href): void {
    const state = this.#oAuthService.nonceStateSeparator! + encodeURIComponent(targetUrl);

    this.#oAuthService.logOut(false, state);
  }

  refreshToken(): Observable<TokenResponse> {
    return fromPromise(this.#oAuthService.refreshToken());
  }

  runInitialLoginSequence(): Promise<void> {
    this.#oAuthService.configure({
      clientId: 'app',
      disablePKCE: false,
      issuer: this.#authConfig.authProviderUrl,
      oidc: true,
      postLogoutRedirectUri: normalizeUrl(`${this.#appBaseHref}/${AuthRoute.SignOutOidc}`),
      redirectUri: normalizeUrl(`${this.#appBaseHref}/${AuthRoute.SignInOidc}`),
      responseType: 'code',
      scope: 'openid profile api offline_access',
      sessionChecksEnabled: true,
      showDebugInformation: false,
    });
    this.#oAuthService.setupAutomaticSilentRefresh({}, 'access_token');

    return this.#oAuthService.loadDiscoveryDocument()
      .then(() => {
        const [, queryString] = this.#location.href.split('?');
        const params = new URLSearchParams(queryString);

        // autologin after registration case
        if (params.has(AuthQueryParam.OtAc)) {
          const targetUrl = new URL(this.#location.href);
          const otac = params.get(AuthQueryParam.OtAc);

          targetUrl.searchParams.delete(AuthQueryParam.OtAc);
          // eslint-disable-next-line camelcase
          this.silentLogout(targetUrl.toString(), { acr_values: `${AuthQueryParam.OtAc}:${otac!}` });

          // stop chain here if OTAC param passed to avoid race conditions
          return Promise.reject();
        }

        if (this.#oAuthService.getRefreshToken()) {
          return this.#oAuthService.refreshToken()
            .then(() => this.isLoggedIn);
        }

        return this.#oAuthService.tryLogin();
      })
      .then(() => {
        if (this.isLoggedIn && this.hasRole('guest')) {
          this.logout(this.state || this.#location.href);

          return Promise.reject(new Error('User has a guest role'));
        }

        return undefined;
      });
  }

  silentLogout(targetUrl?: string, params = {}): void {
    this.#oAuthService.logOut(true);
    this.#oAuthService.initCodeFlow(targetUrl, params);
  }

  #loadGeolocation(): Promise<Geolocation> {
    return firstValueFrom(this.#httpClient.get<Geolocation>(`${this.#authConfig.authProviderUrl}/api/location`));
  }
}
