import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { getState, patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { exhaustMap, map, pipe, switchMap, tap } from 'rxjs';

import { ToastService } from '@ideals/components/toast';
import { EventBusService } from '@ideals/services/event-bus';
import { LocalStorageService } from '@ideals/services/local-storage';

import { CorporateAccountStatusId, CreateProject, CreateStagingProject, ListProject, ProjectBase, RequestError } from '../../models';
import { CorporateAccountService } from '../../services/corporate-account';
import { ProjectsService } from '../../services/projects';
import { AuthUserStore } from '../auth-user';
import { ErrorsStore } from '../errors';

const FAVORITE_PROJECTS_KEY = 'vdr-projects-favorite';

interface ProjectsState {
  readonly administratedProjects: ListProject[];
  readonly favoriteProjectNames: string[];
  readonly loading: boolean;
  readonly projects: ListProject[];
}

const initialState: ProjectsState = {
  administratedProjects: [],
  favoriteProjectNames: [],
  loading: true,
  projects: [],
};

function saveFavoriteProjectNames(localStorageService: LocalStorageService, names: string[]): void {
  localStorageService.setItem(FAVORITE_PROJECTS_KEY, names);
}

export const ProjectsStore = signalStore(
  { protectedState: false },
  withState(initialState),
  withMethods((store) => {
    const projectsService = inject(ProjectsService);
    const authUserStore = inject(AuthUserStore);
    const corporateAccountService = inject(CorporateAccountService);
    const localStorageService = inject(LocalStorageService);
    const errorsStore = inject(ErrorsStore);
    const eventBusService = inject(EventBusService);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    return {
      createProject: rxMethod<CreateProject>(
        pipe(
          exhaustMap((project) => projectsService.createProject(project).pipe(
            tapResponse(
              ({ hostId, name, newIdealsSwitchEnabled, type }) => {
                toastService.success(translateService.instant('common.Toaster.Project_created') as string);
                eventBusService.emit('projects:create-project-success', { hostId, name, newIdealsSwitchEnabled, projectType: type });
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                eventBusService.emit('projects:create-project-failure');
              },
            ),
          )),
        ),
      ),

      createStagingProject: rxMethod<CreateStagingProject>(
        pipe(
          exhaustMap((project) => projectsService.createStagingProject(project).pipe(
            tapResponse(
              (data) => {
                toastService.success(translateService.instant('SH.Toaster.Staging_room_created') as string);
                eventBusService.emit('projects:create-staging-project-success', data);
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                eventBusService.emit('projects:create-staging-project-failure');
              },
            ),
          )),
        ),
      ),

      createTrialProject: rxMethod<{ name: string; sourceProject: ProjectBase; }>(
        pipe(
          exhaustMap(({ name, sourceProject }) => projectsService.createTrialProject(sourceProject, name).pipe(
            tapResponse(
              ({ detailedProject, listProject }) => {
                authUserStore.addAuthUserAccount({
                  id: listProject.accountId,
                  name: listProject.title,
                });

                toastService.success(translateService.instant('common.Toaster.Project_created') as string);

                patchState(store, (state) => ({
                  administratedProjects: [...state.administratedProjects, listProject],
                  projects: [...state.projects, listProject],
                }));

                eventBusService.emit('projects:create-trial-project-success', detailedProject);
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                eventBusService.emit('projects:create-trial-project-failure');
              },
            ),
          )),
        ),
      ),

      loadAdministratedAccounts: rxMethod<void>(
        pipe(
          switchMap(() => corporateAccountService.loadAdministratedAccounts({ status: CorporateAccountStatusId.Active }).pipe(
            tapResponse(
              (administratedProjects) => {
                eventBusService.emit('projects:load-administrated-accounts-success', administratedProjects.administrated);
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                eventBusService.emit('projects:load-administrated-accounts-failure');
              },
            ),
          )),
        ),
      ),

      loadAdministratedProjects: rxMethod<void>(
        pipe(
          switchMap(() => projectsService.loadProjects(true).pipe(
            tapResponse(
              (administratedProjects) => patchState(store, { administratedProjects }),
              (error: RequestError) => errorsStore.requestError(error),
            ),
          )),
        ),
      ),

      loadProjects: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { loading: true })),
          switchMap(() => projectsService.loadProjects().pipe(
            tapResponse(
              (projects) => {
                patchState(store, (state) => ({
                  loading: false,
                  projects: projects.map((project) => ({
                    ...project,
                    isFavorite: state.favoriteProjectNames.includes(project.name),
                  })),
                }));
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                patchState(store, { loading: false });
              },
            ),
          )),
        ),
      ),

      loadServers: rxMethod<string[] | void>(
        pipe(
          switchMap((accountIds) => projectsService.loadProjectServers(authUserStore.user()!.culture, accountIds ?? []).pipe(
            tapResponse(
              (servers) => {
                eventBusService.emit('projects:load-servers-success', servers);
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                eventBusService.emit('projects:load-servers-failure');
              }
            )
          ))
        )
      ),

      loadUseCases: rxMethod<void>(
        pipe(
          switchMap(() => projectsService.loadUseCases().pipe(
            tapResponse(
              (useCases) => {
                eventBusService.emit('projects:load-use-cases-success', useCases);
              },
              (error: RequestError) => {
                errorsStore.requestError(error);
                eventBusService.emit('projects:load-use-cases-failure');
              }
            )
          ))
        )
      ),

      updateFavoriteProject: rxMethod<{ isFavorite: boolean; project: ListProject; }>(
        pipe(
          map(({ isFavorite, project }) => {
            const favoriteProjectNames = getState(store).favoriteProjectNames;
            const updatedFavoriteProjectNames = isFavorite
              ? [...favoriteProjectNames, project.name]
              : favoriteProjectNames.filter((name) => name !== project.name);

            saveFavoriteProjectNames(localStorageService, updatedFavoriteProjectNames);

            eventBusService.emit('projects:update-favorite-project-success');

            patchState(store, (state) => ({
              favoriteProjectNames: updatedFavoriteProjectNames,
              projects: state.projects.map((pr) => pr.name === project.name ? { ...project, isFavorite } : pr),
            }));
          })
        )
      ),

      setUpdatedProject: (name: string, updatedProject: Partial<ProjectBase>): void => {
        patchState(store, (state) => ({
          administratedProjects: state.administratedProjects.map((project) => {
            return project.name === name ? { ...project, ...updatedProject } : project;
          }),
          projects: state.projects.map((project) => {
            return project.name === name ? { ...project, ...updatedProject } : project;
          }),
        }));
      },
    };
  }),
  withHooks({
    onInit: (store, localStorageService = inject(LocalStorageService)): void => {
      const favoriteProjectNames = localStorageService.getItem<string[]>(FAVORITE_PROJECTS_KEY) ?? [];

      patchState(store, { favoriteProjectNames });
    },
  })
);
