import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';

import { AuthService } from './services/auth';

export class ServerErrorHandler implements OAuthResourceServerErrorHandler {
  readonly #authService = inject(AuthService);
  readonly #location: Location = window.location;

  handleError(error: HttpResponse<unknown>): Observable<unknown> {
    const { status } = error;

    if ((status as HttpStatusCode) === HttpStatusCode.Unauthorized) {
      this.#authService.silentLogout(this.#location.href);
    }

    return throwError(() => error);
  }
}
