import { createSelector } from '@ngrx/store';

import { selectRouterState } from '../root-store.selectors';

const selectRouterStoreState = createSelector(
  selectRouterState,
  (state) => state?.state,
);

export const selectQueryParams = createSelector(
  selectRouterStoreState,
  (state) => state?.queryParams,
);

export const selectQueryParam = (name: string) => createSelector(
  selectQueryParams,
  (params) => params?.[name],
);

export const selectRouterData = createSelector(
  selectRouterStoreState,
  (state) => state?.data,
);

export const selectRouterDataItem = <T>(name: string) => createSelector(
  selectRouterData,
  (data) => data?.[name] as T,
);

export const selectRouterParams = createSelector(
  selectRouterStoreState,
  (state) => state?.params,
);

export const selectRouterParam = (name: string) => createSelector(
  selectRouterParams,
  (params) => params?.[name],
);

export const selectRouterUrl = createSelector(
  selectRouterStoreState,
  (state) => state?.url,
);
