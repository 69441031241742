import { inject } from '@angular/core';
import { getState, patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, exhaustMap, map, of, pipe, switchMap, tap } from 'rxjs';

import { ToastService } from '@ideals/components/toast';
import { EventBusService } from '@ideals/services/event-bus';
import { getDomain } from '@ideals/utils/get-domain';
import { VOID } from '@ideals/utils/void';

import { AuthUser, HttpError, UpdateAuthUser, UserAccount } from '../../models';
import { AuthUserService } from '../../services/auth-user';
import { ErrorsStore } from '../errors';

interface AuthUserState {
  readonly loading: boolean;
  readonly user: AuthUser | undefined;
}

const initialState: AuthUserState = {
  loading: true,
  user: undefined,
};

export const AuthUserStore = signalStore(
  { protectedState: false },
  withState(initialState),
  withMethods((store) => {
    const authUserService = inject(AuthUserService);
    const cookieService = inject(CookieService);
    const errorsStore = inject(ErrorsStore);
    const eventBusService = inject(EventBusService);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    return {
      addAuthUserAccount: (account: UserAccount) => {
        const user = getState(store).user;

        patchState(store, {
          user: user && {
            ...user,
            accounts: [...user.accounts, account],
          },
        });
      },

      loadAuthUser: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { loading: true })),
          exhaustMap(() => authUserService.loadAuthUser().pipe(
            tap((user) => patchState(store, { loading: false, user })),
            catchError((error: HttpError) => {
              patchState(store, { loading: false });
              errorsStore.requestError(error);

              return VOID;
            }),
          )),
        ),
      ),

      updateAuthUser: rxMethod<UpdateAuthUser>(
        pipe(
          exhaustMap((user) => authUserService.updateAuthUser(user).pipe(
            switchMap((updatedAuthUser) => {
              const currentAuthUser = getState(store).user;

              if (updatedAuthUser.culture !== currentAuthUser!.culture) {
                cookieService.set('culture', updatedAuthUser.culture, { path: '/', domain: getDomain() });

                return translateService.use(updatedAuthUser.culture).pipe(map(() => updatedAuthUser));
              }

              return of(updatedAuthUser);
            }),
            tap((updatedAuthUser) => {
              const currentAuthUser = getState(store).user;

              patchState(store, { user: currentAuthUser && { ...currentAuthUser, ...updatedAuthUser } });
              toastService.success(translateService.instant('personal_info.SUCCESS.changed_personal_info') as string);
              eventBusService.emit('auth-user:update-auth-user-success');
            }),
            catchError((error: HttpError) => {
              errorsStore.requestError(error);
              eventBusService.emit('auth-user:update-auth-user-failure');

              return VOID;
            }),
          )),
        ),
      ),
    };
  }),
);
