export enum CurrencyId {
  AUD = 36,
  BRL = 986,
  CAD = 124,
  CHF = 756,
  CNY = 156,
  CZK = 203,
  EUR = 978,
  GBP = 826,
  HKD = 344,
  INR = 356,
  RUB = 643,
  SGD = 702,
  SZL = 748,
  UAH = 980,
  USD = 840,
}

export type Currency = 'AUD' |
'BRL' |
'CAD' |
'CHF' |
'CNY' |
'CZK' |
'EUR' |
'GBP' |
'HKD' |
'INR' |
'RUB' |
'SGD' |
'SZL' |
'UAH' |
'USD';
