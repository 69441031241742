import { CurrencyId } from '@ideals/models';

export enum InvoiceStatus {
  Overdue = 1,
  AwaitingPayment,
  Paid,
  Void,
}

export interface InvoiceData {
  readonly amount: number;
  readonly createdAt: Date;
  readonly creditNoteSum: number;
  readonly currency: CurrencyId;
  readonly downloadUrl: string | null;
  readonly invoiceNumber: string;
  readonly paidSum: number;
  readonly payable: boolean;
  readonly remainingAmount: number;
  readonly status: InvoiceStatus;
  readonly statusIsOverdueOrAwaitingPayment: boolean;
}
