import { Injectable } from '@angular/core';

import { DetailedProject } from '../../models';

@Injectable({ providedIn: 'root' })
export class DataTransporterService {
  #project: DetailedProject | undefined = undefined;

  getProject(): DetailedProject | undefined {
    return this.#project;
  }

  setProject(project?: DetailedProject): void {
    this.#project = project;
  }
}
