import { Provider } from '@angular/core';

import { provideQnaSettingsStore } from '../qna-settings';

import { ProjectUsersStore } from './project-users.store';

export function provideProjectUsersStore(): Provider[] {
  return [
    ProjectUsersStore,
    provideQnaSettingsStore(),
  ];
}
