import { outputToObservable } from '@angular/core/rxjs-interop';
import { filter, Observable } from 'rxjs';

import { ToastComponent, ToastMessage } from './toast.component';

export class ToastRef {
  readonly closed$!: Observable<ToastMessage>;

  constructor(
    protected component: ToastComponent,
    protected message: ToastMessage,
  ) {
    this.closed$ = outputToObservable(this.component.closed).pipe(
      filter((toastMessage) => toastMessage === this.message)
    );
  }

  close(): void {
    this.component.close(this.message);
  }
}
