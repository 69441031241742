import { inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  RouterStoreState,
  selectQueryParam,
  selectQueryParams,
  selectRouterData,
  selectRouterDataItem,
  selectRouterParam,
  selectRouterParams,
  selectRouterUrl,
} from './router';

@Injectable({ providedIn: 'root' })
export class RootStoreFacade {
  readonly #store = inject(Store);

  readonly queryParams = this.#store.selectSignal(selectQueryParams);
  readonly routerParams = this.#store.selectSignal(selectRouterParams);
  readonly routerUrl = this.#store.selectSignal(selectRouterUrl);

  get queryParams$(): Observable<RouterStoreState['queryParams']> {
    return this.#store.select(selectQueryParams);
  }

  get routerData$(): Observable<RouterStoreState['data']> {
    return this.#store.select(selectRouterData);
  }

  get routerParams$(): Observable<RouterStoreState['params']> {
    return this.#store.select(selectRouterParams);
  }

  get routerUrl$(): Observable<string> {
    return this.#store.select(selectRouterUrl);
  }

  selectQueryParam(name: string): Observable<string> {
    return this.#store.select(selectQueryParam(name));
  }

  selectRouterDataItem<T>(name: string): Signal<T> {
    return this.#store.selectSignal(selectRouterDataItem<T>(name));
  }

  selectRouterDataItem$<T>(name: string): Observable<T> {
    return this.#store.select(selectRouterDataItem<T>(name));
  }

  selectRouterParam(name: string): Signal<string | undefined> {
    return this.#store.selectSignal(selectRouterParam(name));
  }

  selectRouterParam$(name: string): Observable<string | undefined> {
    return this.#store.select(selectRouterParam(name));
  }
}
