type MessageHandler =
  'changeThemeBackground' |
  'changeThemeColor' |
  'download' |
  'openExternalUrl' |
  'writeToClipboard';

interface PostMessage {
  readonly postMessage: (data: string) => void;
}

interface CustomWindow extends Window {
  readonly flutter_inappwebview: {
    readonly callHandler: (method: MessageHandler, data: string) => void;
  };
  readonly webkit: {
    readonly messageHandlers: Record<MessageHandler, PostMessage>;
  };
}

export function sendMessageToDevice(method: MessageHandler, data: string): void {
  (window as CustomWindow & typeof globalThis).flutter_inappwebview?.callHandler(method, data);
  (window as CustomWindow & typeof globalThis).webkit?.messageHandlers?.[method]?.postMessage(data);
}
