import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { distinctUntilChanged, from, map, switchMap } from 'rxjs';

import { PlatformService, PlatformType } from '@ideals/core/platform';

const dynamicRoutesConfig: Partial<Record<PlatformType, () => Promise<Routes>>> = {
  desktop: () => import('./presentation/desktop/desktop.routes').then((m) => m.desktopRoutes),
  mobile: () => import('./presentation/mobile/mobile.routes').then((m) => m.mobileRoutes),
};

function dynamicRoutesGuard(): true {
  const platformService = inject(PlatformService);
  const router = inject(Router);
  const routes = router.config;

  platformService.platformType$
    .pipe(
      map((platformType) => platformType === 'tablet' ? 'desktop' : platformType),
      distinctUntilChanged(),
      switchMap((platform) => from(dynamicRoutesConfig[platform]!())),
    )
    .subscribe((dynamicRoutes) => {
      router.resetConfig([
        ...dynamicRoutes,
        ...routes.filter((route) => route.path !== '**'),
      ]);
      router.navigateByUrl(router.url);
    });

  return true;
}

export const rootRoutes: Routes = [
  {
    canActivate: [dynamicRoutesGuard],
    children: [],
    path: '**',
  },
];
