import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';

import { downloadFile } from '@ideals/utils/download-file';

type Params = HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; };

@Injectable({ providedIn: 'root' })
export class RemoteDownloadService {
  readonly #http = inject(HttpClient);

  downloadFile(url: string, fileName: string, params?: Params): Observable<number | true> {
    return this.#http
      .get(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'blob',
        params,
      })
      .pipe(
        map((event) => {
          const maxProgress = 100;

          if (event.type === HttpEventType.Response && event.ok) {
            downloadFile(event.body!, fileName);

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return maxProgress;
          }

          if (event.type === HttpEventType.DownloadProgress) {
            return event.total == null ? true : Math.round(event.loaded / event.total * maxProgress);
          }

          return 0;
        }),
        distinctUntilChanged(),
      );
  }
}
