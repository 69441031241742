import { Locale } from 'date-fns';
import { de, enUS, es, fr, it, ja, ko, nl, pl, ptBR, ru, sv, tr, uk, zhCN } from 'date-fns/locale';

import { Culture } from './culture.model';

export const LOCALES: Record<Culture, Locale> = {
  [Culture.BR]: ptBR,
  [Culture.CN]: zhCN,
  [Culture.DE]: de,
  [Culture.ES]: es,
  [Culture.FR]: fr,
  [Culture.IT]: it,
  [Culture.JP]: ja,
  [Culture.KR]: ko,
  [Culture.NL]: nl,
  [Culture.PL]: pl,
  [Culture.RU]: ru,
  [Culture.SE]: sv,
  [Culture.TR]: tr,
  [Culture.UA]: uk,
  [Culture.US]: enUS,
};
