import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { ThemesService } from './themes.service';

export function provideTheming(): Provider[] {
  return [{
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: () => {
      const themesService = inject(ThemesService);

      return () => {
        themesService.initialize();
      };
    },
  }];
}
