import { inject } from '@angular/core';
import { signalStore, withMethods } from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import { ToastService } from '@ideals/components/toast';
import { EventBusService } from '@ideals/services/event-bus';

import { HttpError, HttpErrorForbidden, RequestError } from '../../models';

export const ErrorsStore = signalStore(
  { protectedState: false },
  withMethods(() => {
    const eventBusService = inject(EventBusService);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    return {
      requestError: (err: HttpError | RequestError): void => {
        const defaultMessage = 'common.Error.Something_went_wrong';
        const error = err as HttpError & RequestError;
        let message = '';

        switch (error.status) {
          case 'No Response': {
            message = 'common.ERROR.no_internet';
            break;
          }
          case 'Bad Request': {
            if (error.type === 'Detailed' || error.errors) {
              return;
            }

            message = error.message ?? `${error.detail}(${error.errorCode})`;
            break;
          }
          case 'Unauthorized': {
            return;
          }
          case 'Forbidden': {
            if (error.error) {
              eventBusService.emit<HttpErrorForbidden>('errors:forbidden', error as HttpErrorForbidden);

              return;
            }

            message = 'common.ERROR.insufficient_permissions';
            break;
          }
          case 'Not Found': {
            message = error.method === 'GET' ? 'common.ERROR.page_not_found' : defaultMessage;
            break;
          }
          default: {
            message = defaultMessage;
          }
        }

        toastService.error(translateService.instant(message) as string);
      },
    };
  }),
);
