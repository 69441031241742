import { distinctUntilChanged, filter, interval, map, Observable } from 'rxjs';

const CHECK_INTERVAL = 50;

export function getCssVar(name: string, element: HTMLElement = document.body): string {
  return getComputedStyle(element).getPropertyValue(`--${name}`).trim();
}

export function getCssVar$(name: string): Observable<string> {
  return interval(CHECK_INTERVAL).pipe(
    map(() => getCssVar(name)),
    filter((v) => Boolean(v)),
    distinctUntilChanged(),
  );
}
