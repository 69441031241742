import { InjectionToken, Provider } from '@angular/core';

import { HelpCenterStore } from './help-center.store';

export const HELP_CENTER_API_URL = new InjectionToken<string>('HELP_CENTER_API_URL');

export function provideHelpCenterStore(apiUrl: string): Provider[] {
  return [
    HelpCenterStore,
    {
      provide: HELP_CENTER_API_URL,
      useValue: apiUrl,
    },
  ];
}
