import { RoleDescription, RoleDescriptionDetails } from './role-description.model';

import { AccountPermissions } from './index';

export interface CorporateAccountRoleDescription extends RoleDescription {
  readonly accountAccess: RoleDescriptionDetails[];
  readonly name: string;
  readonly participants: RoleDescriptionDetails[];
  readonly projects: RoleDescriptionDetails[];
}

export const OWNER_DESCRIPTION: CorporateAccountRoleDescription = {
  name: 'common.Role.Owner',
  accountAccess: [
    { label: 'common.TEXT.overview', icon: 'icon-check' },
    { label: 'common.TEXT.projects', icon: 'icon-check' },
    { label: 'common.TEXT.participants', icon: 'icon-check' },
    { label: 'reports.ReportName.Data_storage', icon: 'icon-check' },
    { label: 'CA_page.TAB.billing', icon: 'icon-check' },
    { label: 'common.TAB.Settings', icon: 'icon-check' },
  ],
  projects: [
    { label: 'CA.Participants.RoleCard.All.Create_projects', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Add_project_administrators', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Change_support_access', icon: 'icon-check' },
  ],
  participants: [
    { label: 'CA.Participants.RoleCard.Manager&Owner.Manage_users', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Add_project_coordinators', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Add_account_managers', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Grant_additional_rights', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Owner.Transfer_ownership', icon: 'icon-check' },
  ],
};

export function managerDescription(
  canUpdateFeatures: boolean = false,
  accountPermissions: AccountPermissions[] = []
): CorporateAccountRoleDescription {
  return {
    ...OWNER_DESCRIPTION,
    name: 'CA.Participants.Roles.Manager',
    projects: managerProjects(canUpdateFeatures, accountPermissions),
    participants: [
      { label: 'CA.Participants.RoleCard.Manager&Owner.Manage_users', icon: 'icon-check' },
      { label: 'CA.Participants.RoleCard.Manager&Owner.Add_project_coordinators', icon: 'icon-check' },
      { label: 'CA.Participants.RoleCard.Manager&Owner.Add_account_managers', icon: 'icon-check' },
      { label: 'CA.Participants.RoleCard.Grant_additional_rights', icon: 'icon-cancel' },
      { label: 'CA.Participants.RoleCard.Owner.Transfer_ownership', icon: 'icon-cancel' },
    ],
  };
}

function managerProjects(canUpdateFeatures: boolean = false, accountPermissions: AccountPermissions[] = []): RoleDescriptionDetails[] {
  return [
    { label: 'CA.Participants.RoleCard.All.Create_projects', icon: 'icon-check' },
    {
      label: 'CA.Participants.RoleCard.Manager&Owner.Add_project_administrators',
      ...canUpdateFeatures
        ? { featureId: 'InviteAdministrators' }
        : { icon: accountPermissions.includes('InviteAdministrators') ? 'icon-check' : 'icon-cancel' },
    },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Change_support_access',
      ...canUpdateFeatures
        ? { featureId: 'EditSupportAccess' }
        : { icon: accountPermissions.includes('EditSupportAccess') ? 'icon-check' : 'icon-cancel' } },
  ];
}

export const PROJECT_COORDINATOR_DESCRIPTION: CorporateAccountRoleDescription = {
  name: 'CA.Participants.Roles.Project_coordinator',
  accountAccess: [
    { label: 'common.TEXT.overview', icon: 'icon-cancel' },
    { label: 'common.TEXT.projects', icon: 'icon-cancel' },
    { label: 'common.TEXT.participants', icon: 'icon-cancel' },
    { label: 'reports.ReportName.Data_storage', icon: 'icon-cancel' },
    { label: 'CA_page.TAB.billing', icon: 'icon-cancel' },
    { label: 'common.TAB.Settings', icon: 'icon-cancel' },
  ],
  projects: [
    { label: 'CA.Participants.RoleCard.All.Create_projects', icon: 'icon-check' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Add_project_administrators', icon: 'icon-cancel' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Change_support_access', icon: 'icon-cancel' },
  ],
  participants: [
    { label: 'CA.Participants.RoleCard.Manager&Owner.Manage_users', icon: 'icon-cancel' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Add_project_coordinators', icon: 'icon-cancel' },
    { label: 'CA.Participants.RoleCard.Manager&Owner.Add_account_managers', icon: 'icon-cancel' },
    { label: 'CA.Participants.RoleCard.Grant_additional_rights', icon: 'icon-cancel' },
    { label: 'CA.Participants.RoleCard.Owner.Transfer_ownership', icon: 'icon-cancel' },
  ],
};
