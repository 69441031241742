import { Injectable } from '@angular/core';
import { addMinutes } from 'date-fns';

const EXPIRES_DELIMITER = '~.~';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  clear(): void {
    localStorage.clear();
  }

  getItem<T>(key: string): T | null {
    const data = localStorage.getItem(key);

    if (!data) {
      return null;
    }

    const [value, expires] = data.split(EXPIRES_DELIMITER);

    if (expires) {
      const currentDate = new Date();
      const expiresDate = new Date(expires);

      if (currentDate.getTime() > expiresDate.getTime()) {
        this.removeItem(key);

        return null;
      }
    }

    return JSON.parse(value) as T;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * life: number - minutes
   */
  setItem<T>(key: string, value: T, life?: number): void {
    const data: string[] = [JSON.stringify(value)];

    if (life != null && life > 0) {
      data.push(addMinutes(new Date(), life).toISOString());
    }

    localStorage.setItem(key, data.join(EXPIRES_DELIMITER));
  }
}
