import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { PlatformService } from './platform.service';

export function providePlatform(): Provider[] {
  return [
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => {
        const platformService = inject(PlatformService);

        return () => {
          platformService.initialize();
        };
      },
    },
  ];
}
