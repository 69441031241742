import { inject, Injectable, Provider } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { combineLatest, isObservable, Observable, of, Subject, switchMap } from 'rxjs';

import { PageTitle } from '@ideals/models';

const TITLE_KEY = 'title';
const TITLE_SUFFIX = 'Ideals';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
class PageTitleStrategy extends TitleStrategy {
  readonly #title = inject(Title);

  readonly #title$ = new Subject<Observable<PageTitle>[]>();

  constructor() {
    super();

    this.#title$
      .pipe(switchMap((titles$) => combineLatest(titles$)))
      .subscribe((pageTitles) => {
        const title = pageTitles
          .filter((item, index) => !pageTitles[index + 1]?.replaceParent)
          .map((pageTitle) => pageTitle.value)
          .join(' | ');

        this.#title.setTitle(title);
      });
  }

  updateTitle(routerState: RouterStateSnapshot): void {
    const pageTitles: Observable<PageTitle>[] = [];
    let route = routerState.root;

    while (route) {
      const pageTitle = route.data[TITLE_KEY] as PageTitle | Observable<PageTitle>;

      if (pageTitle) {
        pageTitles.push(isObservable(pageTitle) ? pageTitle : of(pageTitle));
      }

      route = route.firstChild!;
    }

    pageTitles.push(of({ value: TITLE_SUFFIX }));

    this.#title$.next(pageTitles);
  }
}

export function providePageTitleStrategy(): Provider[] {
  return [{
    provide: TitleStrategy,
    useClass: PageTitleStrategy,
  }];
}
