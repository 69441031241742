import { SortMeta } from 'primeng/api/sortmeta';

import { FieldValuesMap, ItemsCache, getItemFieldValue } from '../get-item-field-value';

function isNull<T>(value: T): boolean {
  return typeof value === 'object' && value == null;
}

function valuesComparator<T>(value1: T, value2: T, order: number): number | null {
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    const compareStrings = value1.localeCompare(value2, undefined, { numeric: true });

    if (compareStrings) {
      return compareStrings * order;
    }
  }

  if (isNull(value1) && isNull(value2)) {
    return 0;
  }

  if (isNull(value1) || value1 < value2) {
    return -order;
  }

  if (isNull(value2) || value1 > value2) {
    return order;
  }

  return null;
}

export function sortItems<T extends object>(items: T[], orderByList: SortMeta[], fieldValuesMap?: FieldValuesMap<T>): T[] {
  const cache: ItemsCache<T> = new Map();

  return [...items].sort((item1, item2) => {
    for (const orderBy of orderByList) {
      const value1 = getItemFieldValue<T>(item1, orderBy.field, fieldValuesMap, cache) as string | number | boolean;
      const value2 = getItemFieldValue<T>(item2, orderBy.field, fieldValuesMap, cache) as string | number | boolean;
      const comparation = valuesComparator(value1, value2, orderBy.order);

      if (typeof comparation === 'number') {
        return comparation;
      }
    }

    return 0;
  });
}
