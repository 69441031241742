export enum Culture {
  BR = 'pt-BR',
  CN = 'zh-CN',
  DE = 'de-DE',
  ES = 'es-ES',
  FR = 'fr-FR',
  IT = 'it-IT',
  JP = 'ja-JP',
  KR = 'ko-KR',
  NL = 'nl-NL',
  PL = 'pl-PL',
  RU = 'ru-RU',
  SE = 'sv-SE',
  TR = 'tr-TR',
  UA = 'uk-UA',
  US = 'en-US',
}
