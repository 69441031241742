import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, filter, first, take } from 'rxjs';

import { getCssVar$ } from '@ideals/utils/get-css-var';

import { COVIEW_KEY_TOKEN } from './coview-key.token';

declare global {
  interface Window {
    // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
    Intercom: Intercom;
    // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
    coview: Coview;
    // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
    intercomSettings: IntercomSettings;
  }
}

interface IntercomSettings {
  readonly Full_CA_ID?: string;
  readonly action_color?: string;
  readonly app_id?: string;
  readonly apply_permission_count_admin?: number;
  readonly auth_type?: string;
  readonly background_color?: string;
  readonly contract_number?: string | null;
  readonly email?: string;
  readonly enabled?: boolean;
  readonly first_name?: string;
  readonly hide_default_launcher?: boolean;
  readonly ip?: string;
  readonly is_authenticated?: boolean;
  readonly last_name?: string;
  readonly last_survey_date_at?: number;
  readonly new_ideals_enabled?: boolean;
  readonly new_qna_approve_count?: number;
  readonly new_qna_message_count?: number;
  readonly number_of_move_copy_restore_operations?: number;
  readonly report_export_count_admin?: number;
  readonly report_view_count_admin?: number;
  readonly session_duration?: number;
  readonly subscriptionPlan?: string;
  readonly user_hash?: string;
}

interface IntercomCommandSignature {
  readonly onShow: (callback: () => void) => void;
  readonly show: () => void;
  readonly update: (intercomSettings?: IntercomSettings) => void;
}

type IntercomCommand = keyof IntercomCommandSignature;

type Intercom = <Command extends IntercomCommand>(
  command: Command,
  ...params: Parameters<IntercomCommandSignature[Command]>
) => ReturnType<IntercomCommandSignature[Command]>;

interface CoviewSettings {
  readonly projectKey: string;
}

interface CoviewCommandSignature {
  readonly error: (error: Event) => void;
  readonly message: (message: MessageEvent) => void;
  readonly start: (coviewSettings: CoviewSettings) => void;
}

type CoviewCommand = keyof CoviewCommandSignature;

interface Coview {
  <Command extends CoviewCommand>(
    command: Command,
    ...params: Parameters<CoviewCommandSignature[Command]>
  ): ReturnType<CoviewCommandSignature[Command]>;
  // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
  a: unknown[];
}

@Injectable({ providedIn: 'root' })
export class IntercomService {
  readonly #coviewKey = inject(COVIEW_KEY_TOKEN, { optional: true });
  readonly #document = inject(DOCUMENT);
  readonly #httpClient = inject(HttpClient);
  readonly #intercomLoaded = new BehaviorSubject<boolean>(false);
  readonly #renderer2 = inject(RendererFactory2).createRenderer(null, null);

  get intercom(): Intercom | undefined {
    return window.Intercom;
  }

  initialize(): void {
    this.#httpClient.get<IntercomSettings>('/api/ui-settings/intercom')
      .pipe(
        filter(({ enabled }) => !!enabled),
        take(1),
      )
      .subscribe((intercomSettings) => {
        this.#createIntercomScript(intercomSettings);
        this.#createCoviewScript();
      });
  }

  showIntercomChat(): void {
    this.intercom!('show');
  }

  updateIntercom(intercomSettings: IntercomSettings = {}): void {
    this.#intercomLoaded
      .pipe(
        filter((loaded) => loaded),
        first(),
      )
      .subscribe(() => {
        this.#updateIntercomSettings(intercomSettings);
        this.intercom!('update');
      });
  }

  #createCoviewScript(): void {
    const script = this.#renderer2.createElement('script') as HTMLScriptElement;
    const scriptSrc = 'https://cdn.coview.com/coview.js';
    // eslint-disable-next-line no-multi-assign
    const coview: Coview | undefined = window.coview = window.coview || ((...args) => {
      (window.coview.a = window.coview.a || []).push(args);
    });

    this.#renderer2.setAttribute(script, 'async', '');
    this.#renderer2.setAttribute(script, 'src', scriptSrc);
    this.#renderer2.appendChild(this.#document.head, script);
    this.#renderer2.listen('window', 'message', (event: MessageEvent) => {
      coview!('message', event);
    });
    this.#renderer2.listen('window', 'error', (event: Event) => {
      coview!('error', event);
    });
    script.onload = () => {
      coview!('start', { projectKey: this.#coviewKey! });
    };
  }

  #createIntercomScript(intercomSettings: IntercomSettings): void {
    const script = this.#renderer2.createElement('script') as HTMLScriptElement;
    const scriptSrc = `https://widget.intercom.io/widget/${intercomSettings.app_id!}`;

    this.#updateIntercomSettings(intercomSettings);
    this.#renderer2.setAttribute(script, 'async', '');
    this.#renderer2.setAttribute(script, 'src', scriptSrc);
    this.#renderer2.appendChild(this.#document.head, script);

    script.onload = (): void => {
      this.#intercomLoaded.next(true);
      this.#updateIntercomStyles();
      this.#updateIntercomSurveyDate();
      this.#subscribeToColorChange();
    };
  }

  #subscribeToColorChange(): void {
    getCssVar$('ideals-primary-500').subscribe((color) => {
      this.#updateIntercomSettings({
        // eslint-disable-next-line camelcase
        action_color: color,
        // eslint-disable-next-line camelcase
        background_color: color,
      });
      this.intercom!('update');
    });
  }

  #updateIntercomSettings(intercomSettings: IntercomSettings): void {
    window.intercomSettings = { ...window.intercomSettings, ...intercomSettings };
  }

  #updateIntercomStyles(): void {
    const styles = `
      <style>
        .intercom-1o4wo1x,.e1tiptuf3 { font-size: 0; }
        .intercom-1o4wo1x:after,.e1tiptuf3:after { content: "30 sec."; font-size: 14px; }
      </style>`;

    this.intercom!('onShow', (): void => {
      const intervalTime = 100;
      const intervalId = setInterval(() => {
        const messengerIframe = this.#document.querySelector('.intercom-messenger-frame iframe') as HTMLIFrameElement;
        const messenger = messengerIframe?.contentDocument?.querySelector('.intercom-messenger') as HTMLIFrameElement;

        if (messenger) {
          messengerIframe?.contentDocument?.head?.insertAdjacentHTML('beforeend', styles);
          clearInterval(intervalId);
        }
      }, intervalTime);
    });
  }

  #updateIntercomSurveyDate(): void {
    if (window.intercomSettings?.is_authenticated) {
      const milliseconds = 1000;
      const intervalTime = 1000;
      const intervalId = setInterval(() => {
        const notificationsFrame = (this.#document.querySelector('iframe[name="intercom-notifications-frame"]')
        ?? this.#document.querySelector('iframe[name="intercom-borderless-frame"]')
        ) as HTMLIFrameElement;
        const frameDocument = notificationsFrame?.contentDocument;
        const surveyAuthor = frameDocument?.evaluate(
          '//span[text()="Tasha"]',
          frameDocument,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        );

        if (surveyAuthor?.singleNodeValue) {
          // eslint-disable-next-line camelcase
          this.updateIntercom({ last_survey_date_at: Math.floor(Date.now() / milliseconds) });
          clearInterval(intervalId);
        }
      }, intervalTime);
    }
  }
}
