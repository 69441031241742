export type Shortcut =
  'ctrl+c' |
  'ctrl+x' |
  'ctrl+z' |
  'delete' |
  'shift+f';

const shortcuts: Record<Shortcut, string[]> = {
  'ctrl+c': ['Ctrl + C', '⌘C'],
  'ctrl+x': ['Ctrl + X', '⌘X'],
  'ctrl+z': ['Ctrl + Z', '⌘Z'],
  delete: ['Delete', '⌘Delete'],
  'shift+f': ['Shift + F'],
};

export function getShortcutValue(shortcut: Shortcut): string {
  if (!shortcuts[shortcut]) {
    throw new Error(`Unknown shortcut: ${shortcut}`);
  }

  const values = shortcuts[shortcut];

  return /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? values[1] ?? values[0]
    : values[0];
}
