import { createActionGroup, props } from '@ngrx/store';

import { HttpError, RequestError } from '../../models';
import { FEATURE_KEY } from '../vdr.selectors';

export const errorsActions = createActionGroup({
  source: FEATURE_KEY,
  events: {
    requestError: props<{ error: HttpError | RequestError; }>(),
  },
});
