import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { catchError, from, map, Observable, switchMap, throwError } from 'rxjs';

import { normalizeHttpError } from '../../utils/normalize-http-error';

export function errorInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(request).pipe(
    catchError((response: HttpErrorResponse) => {
      if (response.error instanceof Blob) {
        return from((response.error as Blob)?.text())
          .pipe(
            map((error) => {
              try {
                return JSON.parse(error) as object;
              } catch (e) {
                return error;
              }
            }),
            switchMap((error) => {
              return throwError(() => normalizeHttpError(request.method, { ...response, error }));
            })
          );
      }

      // Note: will be removed after architecture decision about errors across all microservices
      // and all further adjustments on BE
      if ((response.status as HttpStatusCode) === HttpStatusCode.BadRequest
        && (
          [
            `${window.location.origin}/api/identity/password-change`,
            `${window.location.origin}/api/identity/2fa/methods/add`,
            `${window.location.origin}/api/identity/2fa/methods/disable`,
            `${window.location.origin}/api/identity/2fa/verify`,
            `${window.location.origin}/api/identity/2fa/change-authenticator`,
          ].includes(response.url!) && request.method === 'POST'
          || [
            `${window.location.origin}/api/identity/idp/security-settings`,
            `${window.location.origin}/api/identity/change-phone`,
          ].includes(response.url!) && request.method === 'PUT'
        )) {
        return throwError(() => normalizeHttpError(request.method, {
          ...response,
          error: {
            type: 'DETAILED',
            errors: { ...response.error } as object,
          },
        }));
      }

      return throwError(() => normalizeHttpError(request.method, response));
    }),
  );
}
