import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { map, Observable, tap } from 'rxjs';

import { IntercomService } from '@ideals/core/intercom';
import { RemoteDownloadService } from '@ideals/services/remote-download';
import { UploadEvent, UploadService } from '@ideals/services/upload';
import { createHttpParams } from '@ideals/utils/create-http-params';
import { getUserDisplayName } from '@ideals/utils/get-user-display-name';
import { sortItems } from '@ideals/utils/sort-items';

import {
  BulkInviteUser,
  BulkInviteUsersResult,
  DetailedProject,
  DetailedProjectUser,
  InviteProjectUsers,
  ProjectNotificationType,
  ProjectUser,
  ProjectUsersFilter,
  ProjectUserQnaSettings,
  QnaFilter,
  QnaRole,
  UpdateProjectUser,
  ProjectGroup,
  ProjectUserIntercomData,
} from '../../models';
import { getProjectsUrl } from '../../utils/get-projects-url';

export type ProjectUserDto = Omit<ProjectUser, 'group' | 'lastSignIn'> & {
  readonly group: Omit<ProjectGroup, 'accessLevelToIrl'> & {
    readonly accessToIRL: boolean;
  };
  readonly lastSignIn: string | null;
};

interface ProjectUserNotificationFrequency {
  readonly documentNotifications: ProjectNotificationType;
  readonly userId: number;
}

interface DetailedProjectUserDto extends Omit<DetailedProjectUser, 'group'> {
  readonly group: Omit<ProjectGroup, 'accessLevelToIrl'> & {
    readonly accessToIRL: boolean;
  };
}

export const mapUser = ({ lastSignIn, ...rest }: ProjectUserDto): ProjectUser => ({
  ...rest,
  group: {
    ...rest.group,
    accessLevelToIrl: rest.group.accessToIRL ? 'Manage' : 'None',
  },
  lastSignIn: lastSignIn ? new Date(lastSignIn) : null,
});

@Injectable({ providedIn: 'root' })
export class ProjectUsersService {
  readonly #httpClient = inject(HttpClient);
  readonly #intercomService = inject(IntercomService);
  readonly #remoteDownloadService = inject(RemoteDownloadService);
  readonly #translateService = inject(TranslateService);
  readonly #uploadService = inject(UploadService);

  bulkInviteUsers(project: DetailedProject, users: BulkInviteUser[]): Observable<BulkInviteUsersResult> {
    return this.#httpClient.post<BulkInviteUsersResult>(
      getProjectsUrl(project.hostId!, `/${project.id}/users/bulk-invitations`),
      users.map((user) => ({
        company: user.company,
        documentNotification: user.documentNotification,
        email: user.email,
        firstName: user.firstName,
        jobTitle: user.jobTitle,
        language: user.language,
        lastName: user.lastName,
        phone: user.phone ? `+${user.phone}` : '',
        userGroupUniqueId: user.group?.id,
        qnARole: user.qnARole,
        questionTeamId: user.questionTeamId,
      })),
    );
  }

  changeNotificationsFrequency(project: DetailedProject, notifications: ProjectUserNotificationFrequency[]): Observable<void> {
    return this.#httpClient.put<void>(
      getProjectsUrl(project.hostId!, `/${project.id}/users/settings/notifications/frequency`),
      notifications.map((notification) => ({
        frequency: notification.documentNotifications,
        userId: notification.userId,
      })),
    );
  }

  changeQnaRole(project: DetailedProject, users: ProjectUser[], role: QnaRole, teamId?: string): Observable<void> {
    return this.#httpClient.put<void>(
      getProjectsUrl(project.hostId!, `/${project.id}/users/qna-role`),
      {
        qnaTeamId: teamId,
        role,
        userIds: users.map(({ id }) => id),
      },
    );
  }

  deleteUsers(project: DetailedProject, users: ProjectUser[]): Observable<void> {
    const body = {
      userIds: users.map(({ id }) => id),
    };

    return this.#httpClient.delete<void>(getProjectsUrl(project.hostId!, `/${project.id}/users`), { body });
  }

  downloadBulkInviteTemplate(project: DetailedProject): Observable<number | true> {
    return this.#remoteDownloadService.downloadFile(
      getProjectsUrl(project.hostId!, `/${project.id}/users/bulk-invitations/template`),
      `${project.title} – ${this.#translateService.instant('bulkInvitation.ExcelTemplate.FileName.Bulk_invitation') as string}.xlsx`,
    );
  }

  exportUsers(project: DetailedProject, filter: ProjectUsersFilter = {}): Observable<number | true> {
    const params = createHttpParams<ProjectUsersFilter>(filter);
    const fileName = [
      project!.title,
      this.#translateService.instant('common.TEXT.participants'),
      format(new Date(), 'MMM dd, yyyy'),
    ].join(' – ');

    return this.#remoteDownloadService.downloadFile(
      getProjectsUrl(project.hostId!, `/${project.id}/users/report`),
      `${fileName}.xlsx`,
      params
    );
  }

  inviteUsers(
    project: DetailedProject,
    groupId: string,
    inviteUsers: InviteProjectUsers,
    qnaSettings?: ProjectUserQnaSettings
  ): Observable<void> {
    return this.#httpClient.post<void>(getProjectsUrl(project.hostId!, `/${project.id}/users`), {
      documentNotifications: inviteUsers.documentNotifications,
      groupId,
      language: inviteUsers.language,
      securitySettings: inviteUsers.securitySettings,
      users: inviteUsers.emailsWithDetails?.length
        ? inviteUsers.emailsWithDetails!.map(({ email, fullName }) => ({ email, fullName }))
        : inviteUsers.emails.map((email) => ({ email })),
      qnaSettings,
    });
  }

  loadCurrentUser(project: DetailedProject): Observable<ProjectUser> {
    return this.#httpClient.get<ProjectUserDto>(getProjectsUrl(project.hostId!, `/${project.id}/users/current`)).pipe(
      map(mapUser),
      map((user) => ({
        ...user,
        group: {
          ...user.group,
          role: user.isSupport && user.hasSupportAccessToRoom ? 'SupportLimited' : user.group.role,
        },
      })),
    );
  }

  loadCurrentUserIp(project: DetailedProject): Observable<string> {
    return this.#httpClient.get<string>(getProjectsUrl(project.hostId!, `/${project.id}/users/current-ip`));
  }

  loadUser(project: DetailedProject, id: number): Observable<DetailedProjectUser> {
    return this.#httpClient.get<DetailedProjectUserDto>(getProjectsUrl(project.hostId!, `/${project.id}/users/${id}`)).pipe(
      map((user) => ({
        ...user,
        group: {
          ...user.group,
          accessLevelToIrl: user.group.accessToIRL ? 'Manage' : 'None',
          role: user.group.isSupport ? 'SupportLimited' : user.group.role,
        },
        invitedBy: user.invitedBy
          ? { ...user.invitedBy, firstName: user.invitedBy.firstName ?? '', lastName: user.invitedBy.lastName ?? '' }
          : user.invitedBy,
      }))
    );
  }

  loadUsers(project: DetailedProject, filter: ProjectUsersFilter = {}): Observable<ProjectUser[]> {
    const params = createHttpParams<ProjectUsersFilter>(filter);

    return this.#httpClient.get<ProjectUserDto[]>(getProjectsUrl(project.hostId!, `/${project.id}/users`), { params })
      .pipe(map((users) => sortItems<ProjectUser>(
        users.map((user) => ({
          ...mapUser(user),
          group: {
            ...user.group,
            accessLevelToIrl: user.group.accessToIRL ? 'Manage' : 'None',
            role: user.group.isSupport ? 'SupportLimited' : user.group.role,
          },
        })),
        [
          { field: 'isEnabled', order: -1 },
          { field: 'displayName', order: 1 },
          { field: 'lastSignIn', order: -1 },
        ],
        {
          displayName: (_, user: ProjectUser) => getUserDisplayName(user),
        }
      )));
  }

  loadUsersQnaFilter(project: DetailedProject): Observable<QnaFilter> {
    return this.#httpClient.get<QnaFilter>(getProjectsUrl(project.hostId!, `/${project.id}/users/qna-filters`));
  }

  moveToGroup(project: DetailedProject, items: { groupId: string; userId: number; }[]): Observable<void> {
    return this.#httpClient.put<void>(
      getProjectsUrl(project.hostId!, `/${project.id}/users/group`),
      items,
    );
  }

  resendInvitations(project: DetailedProject, users: ProjectUser[]): Observable<void> {
    return this.#httpClient.post<void>(getProjectsUrl(project.hostId!, `/${project.id}/users/invitations`), {
      userIds: users.map((user) => user.id),
    });
  }

  updateUser(project: DetailedProject, id: number, user: UpdateProjectUser): Observable<void> {
    return this.#httpClient.put<void>(
      getProjectsUrl(project.hostId!, `/${project.id}/users/${id}`),
      user
    );
  }

  updateUserIntercomData(project: DetailedProject): void {
    this.#httpClient.get<ProjectUserIntercomData>(getProjectsUrl(project.hostId!, `/${project.id}/users/current/intercom`)).pipe(
      tap(({ qnaData }) => {
        this.#intercomService.updateIntercom({
          /* eslint-disable camelcase*/
          new_qna_approve_count: qnaData.numberOfAnswersApproved,
          new_qna_message_count: qnaData.numberOfMessagesCreated,
          /* eslint-enable camelcase*/
        });
      }),
    ).subscribe();
  }

  updateUsersStatus(project: DetailedProject, users: ProjectUser[], status: boolean): Observable<void> {
    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/users/status`), {
      enabled: status,
      userIds: users.map((user) => user.id),
    });
  }

  uploadBulkInviteTemplate(project: DetailedProject, template: File): Observable<UploadEvent<BulkInviteUser[]>> {
    const formData = new FormData();

    formData.append('file', template);

    return this.#uploadService.uploadFile<BulkInviteUser[]>(
      getProjectsUrl(project.hostId!, `/${project.id}/users/bulk-invitations/template`),
      formData,
    );
  }
}
