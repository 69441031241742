import { checkInputTags } from './check-input-tags.util';
import { checkSelection } from './check-selection.util';
import { formatEventToShortcut } from './format-event-to-shortcut.util';
import { formatShortcutToEvent } from './format-shortcut-to-event.util';

export type ScopeMode = 'override' | 'merge';

export interface Shortcut {
  readonly code: string;
  readonly handler: (event: KeyboardEvent) => void;
}

export interface Scope {
  readonly mode: ScopeMode;
  readonly name: string;
  readonly shortcuts: Shortcut[];
}

let attachedEvent = false;
let scopes: Scope[] = [];

export class ShortcutsManager {
  #created = false;
  #mode!: ScopeMode;
  #scope?: string;
  #shortcuts: Shortcut[] = [];

  add(shortcut: string, handler: (event: KeyboardEvent) => void): this {
    if (this.#created) {
      throw new Error('Cannot add shortcut. Object has already created');
    }

    const event = formatShortcutToEvent(shortcut);
    const code = formatEventToShortcut(event);

    this.#shortcuts.push({ code, handler });

    return this;
  }

  destroy(): void {
    if (!this.#created) {
      throw new Error('Cannot destroy object because it has not been created yet');
    }

    const codes = this.#shortcuts.map((shortcut) => shortcut.code);
    const scope = scopes.find((s) => s.name === (this.#scope ?? 'global'));

    if (scope) {
      const shortcuts = scope.shortcuts.filter((shortcut) => !codes.includes(shortcut.code));

      scopes = shortcuts.length
        ? scopes.map((s) => s === scope ? { ...s, shortcuts } : s)
        : scopes.filter((s) => s !== scope);

      this.#scope = '';
      this.#shortcuts = [];
    }

    ShortcutsManager.detachEvents();
  }

  merge(): this {
    this.#mode = 'merge';
    this.#create();

    return this;
  }

  override(): this {
    this.#mode = 'override';
    this.#create();

    return this;
  }

  scope(scope: string): this {
    if (this.#created) {
      throw new Error('Cannot set scope. Object has already created');
    }

    if (this.#scope) {
      throw new Error(`Cannot set scope. Scope "${this.#scope}" already exists`);
    }

    this.#scope = scope;

    return this;
  }

  #create(): void {
    const scopeName = this.#scope ?? 'global';

    if (this.#created) {
      throw new Error('Cannot create object. Mode already exists');
    }

    if (this.#mode === 'override' && scopeName === 'global') {
      throw new Error('Cannot use "override" method if scope is "global"');
    }

    const scope = scopes.find((s) => s.name === scopeName);

    if (scope) {
      const shortcutExists = this.#shortcuts.some((shortcut) => {
        return scope.shortcuts.some((sc) => sc.code === shortcut.code);
      });

      if (shortcutExists) {
        throw new Error(`Cannot add shortcut to the scope "${scope.name}". Shortcut already exists`);
      }

      if (scope.mode !== this.#mode) {
        throw new Error(`Cannot set different modes in one scope. Current mode: "${scope.mode}", new mode: "${this.#mode}"`);
      }

      scopes = scopes.map((s) => s === scope ? { ...s, shortcuts: [...s.shortcuts, ...this.#shortcuts] } : s);
    } else {
      scopes = [...scopes, {
        mode: this.#mode,
        name: scopeName,
        shortcuts: this.#shortcuts,
      }];
    }

    this.#created = true;

    ShortcutsManager.attachEvents();
  }

  static attachEvents(): void {
    if (!attachedEvent) {
      attachedEvent = true;
      document.addEventListener('keydown', this.handleKeydownEvent);
      window.addEventListener('standaloneBack', this.handleStandaloneBackEvent);
    }
  }

  static detachEvents(): void {
    if (attachedEvent && !scopes.length) {
      attachedEvent = false;
      document.removeEventListener('keydown', this.handleKeydownEvent);
      window.removeEventListener('standaloneBack', this.handleStandaloneBackEvent);
    }
  }

  static handleKeydownEvent(event: KeyboardEvent): void {
    if (event.repeat || checkInputTags(event) || checkSelection(event)) {
      return;
    }

    for (let i = scopes.length - 1; i >= 0; i--) {
      const { shortcuts, mode } = scopes[i];

      for (const shortcut of shortcuts) {
        if (shortcut.code === formatEventToShortcut(event)) {
          // Closes all context menus to prevent unwanted actions via basic hotkeys [FVDR-758]
          document.body.click();

          shortcut.handler(event);
          event.preventDefault();

          return;
        }
      }

      if (mode === 'override') {
        return;
      }
    }
  }

  static handleStandaloneBackEvent(): void {
    ShortcutsManager.handleKeydownEvent(new KeyboardEvent('keydown', { code: 'Back' }));
  }
}
