export function adjustLocaleDateFormatToPrimeNg(dateFormat: string): string {
  return dateFormat.replace(/(MMM)|(MM)|(M)|(y)/g, (match, p1, p2, p3, p4): string => {
    if (p1) {
      return 'M';
    }
    if (p2) {
      return 'mm';
    }
    if (p3) {
      return 'm';
    }
    if (p4) {
      return 'yy';
    }

    return match;
  });
}
