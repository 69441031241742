import { HttpClient, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';

const MAX_PROGRESS = 100;

export interface UploadEvent<T = unknown> {
  readonly progress: number;
  readonly response?: HttpResponse<T>;
}

@Injectable({ providedIn: 'root' })
export class UploadService {
  readonly #http = inject(HttpClient);

  uploadFile<T>(url: string, formData: FormData, params?: HttpParams): Observable<UploadEvent<T>> {
    return this.#http
      .post<T>(
      url,
      formData,
      {
        params,
        observe: 'events',
        reportProgress: true,
      }
    )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return event.total ? { progress: Math.round(event.loaded / event.total * MAX_PROGRESS) } : { progress: 0 };

            case HttpEventType.Response:
              return { progress: MAX_PROGRESS, response: event };

            default:
              return { progress: 0 };
          }
        }),
        distinctUntilChanged(),
      );
  }
}
