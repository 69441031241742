import { HttpClient } from '@angular/common/http';
import { computed, effect, inject, Injectable, Injector, signal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { getBaseHref } from '@ideals/utils/get-base-href';
import { getCssVar$ } from '@ideals/utils/get-css-var';
import { getDomain } from '@ideals/utils/get-domain';
import { sendMessageToDevice } from '@ideals/utils/send-message-to-device';

export const THEME_NAMES = [
  'malachite',
  'emerald',
  'indigo',
  'emperor',
  'picton',
  'persian',
  'burnt-sienna',
  'christine',
  'disco',
] as const;

export type ThemeName = (typeof THEME_NAMES)[number];

const DEFAULT_THEME_NAME: ThemeName = 'malachite';
const THEMES_REMAP: Record<string, ThemeName> = {
  '#ef5350': 'burnt-sienna',
  '#ce1141': 'burnt-sienna',
  '#c72027': 'burnt-sienna',
  '#ec407a': 'burnt-sienna',
  '#f4640c': 'christine',
  '#ff7043': 'christine',
  '#f7a738': 'christine',
  '#e96a48': 'christine',
  '#891157': 'disco',
  '#ab47bc': 'disco',
  '#3bae5b': 'emerald',
  '#8bc34a': 'emerald',
  '#4caf50': 'emerald',
  '#66bb6a': 'emerald',
  '#535353': 'emperor',
  '#3e3e3e': 'emperor',
  '#616161': 'emperor',
  '#8d6e63': 'emperor',
  '#4862d3': 'indigo',
  '#4055b3': 'indigo',
  '#536dfe': 'indigo',
  '#9575cd': 'indigo',
  '#5c6bc0': 'indigo',
  '#2c9c74': 'malachite',
  '#009688': 'persian',
  '#24998C': 'persian',
  '#26a69a': 'persian',
  '#26c6da': 'persian',
  '#4894b0': 'persian',
  '#607d8b': 'persian',
  '#358ceb': 'picton',
  '#42a5f5': 'picton',
  '#6191ca': 'picton',
  '#29b6f6': 'picton',
  '#00A1CD': 'picton',
};
const THEME_DARK_KEY = 'ideals-theme-dark';

@Injectable({ providedIn: 'root' })
export class ThemesService {
  readonly #cookieService = inject(CookieService);
  readonly #dark = signal(matchMedia('(prefers-color-scheme: dark)').matches);
  readonly #httpClient = inject(HttpClient);
  readonly #injector = inject(Injector);
  readonly #themeContents = new Map<string, string>();
  readonly #themeName = signal(DEFAULT_THEME_NAME);

  #styleElement!: HTMLStyleElement;
  #transitionOffStyleElement!: HTMLStyleElement;

  readonly dark = computed(() => this.#dark());
  readonly themeName = computed(() => this.#themeName());

  colorToThemeName(color: string): ThemeName {
    if (THEME_NAMES.includes(color as ThemeName)) {
      return color as ThemeName;
    }

    const themeName = THEMES_REMAP[color.toLowerCase()];

    if (themeName) {
      return themeName;
    }

    console.warn(`Cannot find theme by color (${color}). Current them is used.`);

    return this.themeName();
  }

  initialize(): void {
    const isDark = this.#cookieService.check(THEME_DARK_KEY)
      ? JSON.parse(this.#cookieService.get(THEME_DARK_KEY)) as boolean
      : this.#dark();

    let element = document.head.querySelector('meta[name="theme-color"]')!;

    this.#dark.set(isDark);
    this.#styleElement = this.#createStyleElement();
    this.#transitionOffStyleElement = this.#createTransitionOffStyleElement();
    this.#updateTheme();

    getCssVar$('ideals-main-bg').subscribe((color) => {
      if (element) {
        element.remove();
      }

      document.documentElement.style.display = 'none';
      document.body.style.backgroundColor = color;

      setTimeout(() => {
        document.documentElement.style.display = 'block';
        document.body.style.backgroundColor = color;

        element = document.createElement('meta');
        element.setAttribute('name', 'theme-color');
        element.setAttribute('content', color);
        document.head.appendChild(element);
      });

      sendMessageToDevice('changeThemeBackground', color);
    });

    getCssVar$('ideals-primary-500').subscribe((color) => {
      sendMessageToDevice('changeThemeColor', color);
    });

    const htmlElement = document.querySelector('html')!;

    effect(() => {
      if (this.dark()) {
        htmlElement.classList.add(THEME_DARK_KEY);
      } else {
        htmlElement.classList.remove(THEME_DARK_KEY);
      }
    }, { injector: this.#injector });
  }

  restoreDefault(): void {
    this.setThemeName(DEFAULT_THEME_NAME);
  }

  setThemeBrightness(dark: boolean): void {
    if (dark !== this.#dark()) {
      const expires = new Date();

      expires.setFullYear(expires.getFullYear() + 1);
      this.#dark.set(dark);
      this.#cookieService.set(THEME_DARK_KEY, JSON.stringify(dark), { path: '/', domain: getDomain(), expires });
      this.#updateTheme();
    }
  }

  setThemeName(name: ThemeName): Promise<void> {
    if (name === this.themeName()) {
      return Promise.resolve();
    }

    this.#themeName.set(name);

    return this.#updateTheme();
  }

  #createStyleElement(): HTMLStyleElement {
    const element = document.createElement('style');

    document.head.appendChild(element);

    return element;
  }

  #createTransitionOffStyleElement(): HTMLStyleElement {
    const element = document.createElement('style');

    element.textContent = '* { transition-duration: 0ms !important; }';
    document.head.appendChild(element);
    element.disabled = true;

    return element;
  }

  #getThemePath(dark: boolean): string {
    return `${getBaseHref()}${this.themeName()}-${dark ? 'dark' : 'light'}.css`;
  }

  #updateTheme(): Promise<void> {
    const path = this.#getThemePath(this.dark());
    const content = this.#themeContents.get(path);

    return new Promise((resolve) => {
      if (content) {
        this.#transitionOffStyleElement.disabled = false;
        this.#styleElement.textContent = content;
        setTimeout(() => {
          this.#transitionOffStyleElement.disabled = true;
          resolve();
        });
      } else {
        const oppositeThemePath = this.#getThemePath(!this.dark());

        this.#httpClient.get(oppositeThemePath, { responseType: 'text' }).subscribe((styles) => {
          this.#themeContents.set(oppositeThemePath, styles);
        });

        this.#httpClient.get(path, { responseType: 'text' }).subscribe((styles) => {
          this.#themeContents.set(path, styles);

          this.#updateTheme().then(resolve);
        });
      }
    });
  }
}
