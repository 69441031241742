import { APP_BASE_HREF } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ideals-auth-sign-out-container',
  standalone: true,
  template: '',
})
export class AuthSignOutOidcContainer implements OnInit {
  readonly #appBaseHref = inject(APP_BASE_HREF);
  readonly #authService = inject(AuthService);

  ngOnInit(): void {
    const state = this.#authService.state || this.#appBaseHref;
    const targetUrl = decodeURIComponent(state);

    this.#authService.initLogin(targetUrl);
  }
}
