import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { getState, patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { catchError, exhaustMap, finalize, pipe, skipWhile, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { ToastMessageButton, ToastRef, ToastService } from '@ideals/components/toast';
import { EventBusService } from '@ideals/services/event-bus';
import { uniqueId } from '@ideals/utils/unique-id';
import { VOID } from '@ideals/utils/void';

import {
  BulkInviteUser,
  BulkInviteUserFields,
  HttpError,
  InviteProjectUsers,
  ProjectAccess,
  ProjectGroup,
  ProjectNotificationType,
  ProjectUser,
  ProjectUserQnaSettings,
  ProjectUsersFilter,
  QnaFilter,
  QnaRole,
} from '../../models';
import { ProjectUsersService } from '../../services/project-users';
import { errorsActions, ErrorsStore } from '../errors';
import { ProjectStore } from '../project';
import { QnaSettingsStore } from '../qna-settings';

interface ProjectUsersState {
  readonly bulkInviteTemplateUploading: boolean;
  readonly bulkInviteUsers: BulkInviteUser[] | undefined;
  readonly bulkInviting: boolean;
  readonly currentUserIp: string | undefined;
  readonly exporting: boolean;
  readonly inviting: boolean;
  readonly users: ProjectUser[] | undefined;
  readonly usersLoading: boolean;
  readonly usersQnaFilter: QnaFilter | undefined;
  readonly usersQnaFilterLoading: boolean;
}

const initialState: ProjectUsersState = {
  bulkInviteTemplateUploading: false,
  bulkInviteUsers: undefined,
  bulkInviting: false,
  currentUserIp: undefined,
  exporting: false,
  inviting: false,
  users: undefined,
  usersLoading: false,
  usersQnaFilter: undefined,
  usersQnaFilterLoading: false,
};

function getGroupsUsers(users: ProjectUser[]): Record<string, ProjectUser[]> {
  const groupsUsers: Record<string, ProjectUser[]> = {};

  for (const user of users) {
    if (Array.isArray(groupsUsers[user.group.id])) {
      groupsUsers[user.group.id].push(user);
    } else {
      groupsUsers[user.group.id] = [user];
    }
  }

  return groupsUsers;
}

const BULK_INVITE_TOASTER_LIFE = 10000;

export const ProjectUsersStore = signalStore(
  { protectedState: false },
  withState(initialState),
  withComputed(({ bulkInviteUsers, users }) => ({
    bulkInviteUsersWithErrors: computed(() => (bulkInviteUsers() ?? [])
      .filter(({ errors }) => errors && Object.values(errors).some((value) => !!value))),
    bulkInviteUsersWithWarnings: computed(() => (bulkInviteUsers() ?? [])
      .filter(({ warnings }) => warnings && Object.values(warnings).some((value) => value?.length))),
    groupsUsers: computed(() => users() ? getGroupsUsers(users()!) : undefined),
    userEmails: computed(() => users() ? users()!.map(({ email }) => email) : []),
    usersEmailDomains: computed(() => users()
      ? [...new Set(users()!.filter(({ isSupport }) => !isSupport).map(({ email }) => email.split('@')[1]))]
      : []),
  })),
  withMethods((store) => {
    const errorsStore = inject(ErrorsStore);
    const eventBusService = inject(EventBusService);
    const projectStore = inject(ProjectStore);
    const projectUsersService = inject(ProjectUsersService);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);
    const qnaSettingsStore = inject(QnaSettingsStore);

    const changeUsersStatusFailure = (error: HttpError): void => {
      eventBusService.emit('project-users:change-users-status-failure');
      errorsStore.requestError(error);
    };
    const changeUsersStatusSuccess = (users: ProjectUser[], status: boolean): void => {
      const userIds = new Set(users.map((user) => user.id));
      const updatedUsers = getState(store).users!.map((user) => {
        let projectAccess: ProjectAccess | undefined = undefined;

        if (userIds.has(user.id)) {
          projectAccess = status ? 'Unlimited' : 'Disabled';
        }

        return {
          ...user,
          isAccessAllowed: userIds.has(user.id) ? status : user.isAccessAllowed,
          securitySettings: {
            ...user.securitySettings,
            accessTo: userIds.has(user.id) ? null : user.securitySettings.accessTo,
            projectAccess: projectAccess ?? user.securitySettings.projectAccess,
          },
        };
      });

      eventBusService.emit('project-users:change-users-status-success');
      patchState(store, { users: updatedUsers });
    };
    const deleteUsersFailure = (error: HttpError): void => {
      eventBusService.emit('project-users:delete-users-failure');
      errorsStore.requestError(error);
    };
    const deleteUsersSuccess = (users: ProjectUser[]): void => {
      const usersIds = new Set(users.map(({ id }) => id));

      eventBusService.emit('project-users:delete-users-success');
      patchState(store, { users: getState(store).users!.filter((user) => !usersIds.has(user.id)) });
    };
    const undoChangeNotificationsFrequency = rxMethod<ProjectUser[]>(
      pipe(
        switchMap((users) => {
          const userFrequencies = users.map(({ documentNotifications, id }) => ({ documentNotifications, userId: id }));
          const userWithFrequencies = users.reduce((acc, user) => ({
            ...acc,
            [user.id]: user.documentNotifications,
          }), {} as Record<number, ProjectNotificationType>);

          return projectUsersService.changeNotificationsFrequency(projectStore.project()!, userFrequencies).pipe(
            tapResponse(
              () => {
                toastService.success(translateService.instant('common.Toaster.TEXT.Action_undone') as string, { id: uniqueId() });
                patchState(store, {
                  users: getState(store).users!.map((user) => ({
                    ...user,
                    documentNotifications: userWithFrequencies[user.id] ?? user.documentNotifications,
                  })),
                });
              },
              (error: HttpError) => errorsStore.requestError(error)
            ),
          );
        }),
      ),
    );
    const undoChangeUsersStatus = rxMethod<ProjectUser[]>(
      pipe(
        switchMap((users) => {
          return projectUsersService.updateUsersStatus(projectStore.project()!, users, users[0].isAccessAllowed)
            .pipe(
              tapResponse(
                () => {
                  changeUsersStatusSuccess(users, users[0].isAccessAllowed);
                  toastService.success(translateService.instant('common.Toaster.TEXT.Action_undone') as string, { id: uniqueId() });
                },
                (error: HttpError) => changeUsersStatusFailure(error)
              ),
            );
        }),
      ),
    );
    const undoMoveToGroup = rxMethod<ProjectUser[]>(
      pipe(
        switchMap((users) => {
          const items = users.map(({ group, id }) => ({
            groupId: group.id,
            userId: id,
          }));

          return projectUsersService.moveToGroup(projectStore.project()!, items)
            .pipe(
              tapResponse(
                () => {
                  const groupByUserId = users.reduce((acc, user) => {
                    return {
                      ...acc,
                      [user.id]: user.group,
                    };
                  }, {} as Record<number, ProjectGroup>);

                  patchState(store, {
                    users: getState(store).users!.map((user) => ({
                      ...user,
                      group: groupByUserId[user.id] ?? user.group,
                    })),
                  });
                  eventBusService.emit('project-users:move-to-group-success');
                  toastService.success(translateService.instant('common.Toaster.TEXT.Action_undone') as string);
                },
                (error: HttpError) => {
                  eventBusService.emit('project-users:move-to-group-failure');
                  errorsStore.requestError(error);
                }
              )
            );
        })
      )
    );

    return {
      changeGroupStatus: (groups: ProjectGroup[], status: boolean) => {
        const groupsIds = new Set(groups.map(({ id }) => id));
        const users = getState(store).users!.map((user) => {
          let projectAccess: ProjectAccess | undefined = undefined;

          if (groupsIds.has(user.group.id)) {
            projectAccess = status ? 'Unlimited' : 'Disabled';
          }

          return {
            ...user,
            group: {
              ...user.group,
              accessTo: groupsIds.has(user.group.id) ? null : user.group.accessTo,
              isEnabled: groupsIds.has(user.group.id) ? status : user.group.isEnabled,
              projectAccess: projectAccess ?? user.group.projectAccess,
            },
            securitySettings: {
              ...user.securitySettings,
              accessTo: groupsIds.has(user.group.id) ? null : user.securitySettings.accessTo,
              projectAccess: projectAccess ?? user.securitySettings.projectAccess,
            },
          };
        });

        patchState(store, { users });
      },

      changeNotificationsFrequency: rxMethod<{ frequency: ProjectNotificationType; users: ProjectUser[]; }>(
        pipe(
          exhaustMap(({ frequency, users }) => {
            const userFrequencies = users.map(({ id }) => ({
              documentNotifications: frequency,
              userId: id,
            }));
            const userIds = new Set(users.map(({ id }) => id));

            return projectUsersService.changeNotificationsFrequency(projectStore.project()!, userFrequencies).pipe(
              tapResponse(
                () => {
                  const buttons: ToastMessageButton[] = [
                    {
                      command: () => undoChangeNotificationsFrequency(users),
                      shortcut: 'ctrl+z',
                      label: translateService.instant('common.BTN.undo') as string,
                    },
                  ];

                  toastService.success(
                    translateService.instant('participants.Toaster.Notification_settings_updated') as string,
                    { buttons },
                  );
                  patchState(store, {
                    users: getState(store).users!.map((user) => ({
                      ...user,
                      documentNotifications: userIds.has(user.id) ? frequency : user.documentNotifications,
                    })),
                  });
                },
                (error: HttpError) => errorsStore.requestError(error)
              )
            );
          }),
        ),
      ),

      changeQnaRole: rxMethod<{ role: QnaRole; teamId?: string; users: ProjectUser[]; }>(
        pipe(
          exhaustMap(({ role, teamId, users }) => {
            const currentUser = projectStore.currentUser();

            return projectUsersService.changeQnaRole(projectStore.project()!, users, role, teamId).pipe(
              tapResponse(
                () => {
                  const isCurrentUserUpdated = users.some((user) => user.id === currentUser!.id);
                  const hasQuestionSideUser = users.some((user) => ['QuestionDrafter', 'QuestionSubmitter'].includes(user.qnaRole));
                  const userIds = new Set(users.map(({ id }) => id));

                  if (isCurrentUserUpdated) {
                    projectStore.updateProjectUser({ ...currentUser!, qnaRole: role });
                  }

                  if (!!teamId || hasQuestionSideUser) {
                    qnaSettingsStore.updateQuestionTeamsUsersSuccess(users, role, teamId);
                  }

                  patchState(store, {
                    users: getState(store).users!.map((user) => ({
                      ...user,
                      qnaRole: userIds.has(user.id) ? role : user.qnaRole,
                    })),
                  });
                  eventBusService.emit('project-users:change-qna-role-success');
                  toastService.success(translateService.instant('participants.Toaster.Q&A_role_changed') as string);
                },
                (error: HttpError) => {
                  eventBusService.emit('project-users:change-qna-role-failure');
                  errorsStore.requestError(error);
                }
              )
            );
          })
        )
      ),

      changeUsersStatus: rxMethod<{ status: boolean; users: ProjectUser[]; }>(
        pipe(
          exhaustMap(({ status, users }) => {
            return projectUsersService.updateUsersStatus(projectStore.project()!, users, status)
              .pipe(
                tapResponse(
                  () => {
                    const buttons: ToastMessageButton[] = [
                      {
                        command: () => undoChangeUsersStatus(users),
                        shortcut: 'ctrl+z',
                        label: translateService.instant('common.BTN.undo') as string,
                      },
                    ];
                    const statusText = status ? 'activated' : 'deactivated';
                    const countText = users.length === 1 ? '' : 's';
                    const message = `participants.Toaster.Participant${countText}_${statusText}`;

                    changeUsersStatusSuccess(users, status);
                    toastService.success(translateService.instant(message, { number: users.length }) as string, { buttons });
                  },
                  (error: HttpError) => {
                    eventBusService.emit('project-users:change-users-status-failure');
                    errorsStore.requestError(error);
                  }
                )
              );
          })
        )
      ),

      changeUsersStatusFailure,

      changeUsersStatusSuccess,

      clearBulkInviteUsers: () => patchState(store, { bulkInviteUsers: undefined }),

      clearUsers: () => patchState(store, { users: undefined, usersLoading: false }),

      deleteBulkInviteUser: (id: string) => {
        const users = getState(store).bulkInviteUsers!;

        patchState(store, {
          bulkInviteUsers: users.length === 1 && users[0].id === id ? undefined : users.filter((user) => user.id !== id),
        });
      },

      deleteUsers: rxMethod<ProjectUser[]>(
        pipe(
          exhaustMap((users) => {
            return projectUsersService.deleteUsers(projectStore.project()!, users)
              .pipe(
                tapResponse(
                  () => {
                    const message = users.length === 1 ? 'common.TEXT.Participant_deleted' : 'common.TEXT.N_participants_deleted';

                    toastService.success(translateService.instant(message, { usersCount: users.length }) as string);
                    deleteUsersSuccess(users);
                  },
                  (error: HttpError) => deleteUsersFailure(error)
                )
              );
          }),
        ),
      ),

      deleteUsersFailure,

      deleteUsersSuccess,

      downloadBulkInviteTemplate: rxMethod<void>(
        pipe(
          switchMap(() => {
            const toastRef = toastService.info(translateService.instant('documents.Toaster.TEXT.Preparing_file') as string);

            return projectUsersService.downloadBulkInviteTemplate(projectStore.project()!)
              .pipe(
                catchError(() => {
                  toastService.error(translateService.instant('common.Toaster.Download_failed') as string);

                  return VOID;
                }),
                finalize(() => toastRef.close()),
              );
          }),
        )
      ),

      exportUsers: rxMethod<ProjectUsersFilter>(
        pipe(
          tap(() => patchState(store, { exporting: true })),
          switchMap((usersFilter) => {
            // TODO Add localization
            const toastRef = toastService.info(
              translateService.instant('Preparing the participants index for export... This can take a few moments.') as string,
              { icon: 'icon-spin icon-spinner', sticky: true },
            );

            return projectUsersService.exportUsers(projectStore.project()!, usersFilter)
              .pipe(
                catchError((error: HttpError) => {
                  errorsStore.requestError(error);
                  toastService.error(
                    translateService.instant('Participants couldn\'t be exported. Try again later.') as string
                  );

                  return VOID;
                }),
                finalize(() => {
                  toastRef?.close();

                  patchState(store, { exporting: false });
                }),
              );
          }),
        ),
      ),

      inviteBulkUsers: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { bulkInviting: true })),
          exhaustMap(() => {
            const bulkInviteUsers = getState(store).bulkInviteUsers!;

            return projectUsersService.bulkInviteUsers(projectStore.project()!, bulkInviteUsers).pipe(
              tapResponse(
                (result) => {
                  let message = '';

                  if (result.failed.length || result.invitedWithoutQnARole.length) {
                    let toastRef: ToastRef | null = null;
                    const showResultsTimeout = setTimeout(() => {
                      eventBusService.emit('project-users:bulk-invite-users-show-results', result);
                      toastRef?.close();
                    }, BULK_INVITE_TOASTER_LIFE);

                    if (result.failed.length === bulkInviteUsers.length) {
                      message = 'bulkInvite.Toaster.Failed_to_invite_participants';

                      toastRef = toastService.error(translateService.instant(message) as string, {
                        buttons: [{
                          command: () => eventBusService.emit('project-users:bulk-invite-users-show-results', result),
                          label: translateService.instant('common.BTN.Show_errors') as string,
                        }],
                        life: BULK_INVITE_TOASTER_LIFE,
                      });
                    } else {
                      message = translateService.instant('common.TEXT.N_participants_invited', {
                        usersCount: result.invited.length + result.invitedWithoutQnARole.length,
                      }) as string;

                      if (result.invitedWithoutQnARole.length) {
                        message += ` ${translateService.instant('bulkInvite.Toaster.N_invited_with_warnings', {
                          number: result.invitedWithoutQnARole.length,
                        }) as string}`;
                      }

                      if (result.failed.length) {
                        message += `, ${translateService.instant('common.TEXT.N_failed', {
                          number: result.failed.length,
                        }) as string}`;
                      }

                      toastRef = toastService.warn(message, {
                        buttons: [{
                          command: () => eventBusService.emit('project-users:bulk-invite-users-show-results', result),
                          label: translateService.instant('common.TEXT.Show_details') as string,
                        }],
                        life: BULK_INVITE_TOASTER_LIFE,
                      });
                    }

                    toastRef.closed$.subscribe(() => {
                      clearTimeout(showResultsTimeout);
                    });
                  } else {
                    message = result.invited.length === 1 ? 'common.TEXT.Participant_invited' : 'common.TEXT.N_participants_invited';

                    toastService.success(translateService.instant(message, { usersCount: result.invited.length }) as string);
                  }

                  eventBusService.emit('project-users:bulk-invite-users-success');
                  patchState(store, { bulkInviting: false });
                },
                (error: HttpError) => {
                  eventBusService.emit('project-users:bulk-invite-users-failure', error);
                  errorsActions.requestError({ error });
                  patchState(store, { bulkInviting: false });
                }
              )
            );
          })
        ),
      ),

      inviteUsers: rxMethod<{ groupId: string; qnaSettings?: ProjectUserQnaSettings; users: InviteProjectUsers; }>(
        pipe(
          tap(() => patchState(store, { inviting: true })),
          exhaustMap(({ groupId, qnaSettings, users }) => {
            return projectUsersService.inviteUsers(projectStore.project()!, groupId, users, qnaSettings)
              .pipe(
                tapResponse(
                  () => {
                    const message = users.emails!.length === 1 ? 'common.TEXT.Participant_invited' : 'common.TEXT.N_participants_invited';

                    patchState(store, { inviting: false });
                    toastService.success(translateService.instant(message, { usersCount: users.emails!.length }) as string);
                    eventBusService.emit('project-users:invite-users-success');
                  },
                  (error: HttpError) => {
                    patchState(store, { inviting: false });
                    eventBusService.emit('project-users:invite-users-failure');
                    errorsStore.requestError(error);
                  }
                )
              );
          }),
        ),
      ),

      loadCurrentUserIp: rxMethod<void>(
        pipe(
          exhaustMap(() => {
            return projectUsersService.loadCurrentUserIp(projectStore.project()!)
              .pipe(
                tapResponse(
                  (currentUserIp) => patchState(store, { currentUserIp }),
                  (error: HttpError) => errorsStore.requestError(error)
                )
              );
          }),
        ),
      ),

      loadUsers: rxMethod<ProjectUsersFilter | void>(
        pipe(
          tap(() => patchState(store, { usersLoading: true })),
          exhaustMap((filter) => {
            return projectUsersService.loadUsers(projectStore.project()!, filter ?? undefined)
              .pipe(
                tapResponse(
                  (users) => patchState(store, { users, usersLoading: false }),
                  (error: HttpError) => {
                    patchState(store, { usersLoading: false });
                    eventBusService.emit('project-users:load-users-failure');
                    errorsStore.requestError(error);
                  }
                )
              );
          }),
        ),
      ),

      loadUsersQnaFilter: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { usersQnaFilterLoading: true })),
          exhaustMap(() => {
            return projectUsersService.loadUsersQnaFilter(projectStore.project()!)
              .pipe(
                tapResponse(
                  (usersQnaFilter) => patchState(store, { usersQnaFilter, usersQnaFilterLoading: false }),
                  (error: HttpError) => {
                    patchState(store, { usersQnaFilterLoading: false });
                    errorsStore.requestError(error);
                  }
                )
              );
          }),
        ),
      ),

      moveToGroup: rxMethod<{ group: ProjectGroup; users: ProjectUser[]; }>(
        pipe(
          exhaustMap(({ group, users }) => {
            const items = users.map(({ id }) => ({
              groupId: group.id,
              userId: id,
            }));

            return projectUsersService.moveToGroup(projectStore.project()!, items)
              .pipe(
                tapResponse(
                  () => {
                    const buttons: ToastMessageButton[] = [
                      {
                        command: () => undoMoveToGroup(users),
                        shortcut: 'ctrl+z',
                        label: translateService.instant('common.BTN.undo') as string,
                      },
                    ];
                    const userIds = new Set(users.map(({ id }) => id));

                    toastService.success(
                      users.length === 1
                        ? translateService.instant('participants.Toaster.Participant_moved') as string
                        : translateService.instant('participants.Toaster.Participants_moved', { number: users.length }) as string,
                      { buttons },
                    );

                    patchState(store, {
                      users: getState(store).users!.map((user) => ({
                        ...user,
                        group: userIds.has(user.id) ? group : user.group,
                      })),
                    });
                    eventBusService.emit('project-users:move-to-group-success');
                  },
                  (error: HttpError) => {
                    eventBusService.emit('project-users:move-to-group-failure');
                    errorsStore.requestError(error);
                  }
                )
              );
          }),
        ),
      ),

      resendInvitations: rxMethod<ProjectUser[]>(
        pipe(
          exhaustMap((users) => {
            return projectUsersService.resendInvitations(projectStore.project()!, users)
              .pipe(
                tap(() => {
                  toastService.success(
                    users.length === 1
                      ? translateService.instant('common.TEXT.Invitation_resent') as string
                      : translateService.instant('common.TEXT.N_invitations_resent', { number: users.length }) as string
                  );
                }),
              );
          }),
        ),
      ),

      updateBulkInviteUser: (field: keyof BulkInviteUserFields, user: BulkInviteUserFields) => {
        patchState(store, {
          bulkInviteUsers: getState(store).bulkInviteUsers!.map((item) => {
            if (item.id !== user.id) {
              return item;
            }

            let updatedUser = { ...item, ...user };

            if (updatedUser.errors?.[field]) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { [field]: _, ...errors } = updatedUser.errors;

              updatedUser = { ...updatedUser, errors };
            }

            if (updatedUser.warnings?.[field]) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { [field]: _, ...warnings } = updatedUser.warnings;

              updatedUser = { ...updatedUser, warnings };
            }

            if (field === 'email' && getState(store).users!.some(({ email }) => email === updatedUser.email)) {
              updatedUser = { ...updatedUser, errors: { ...updatedUser.errors, email: 'UserAlreadyExisted' } };
            }

            return updatedUser;
          }),
        });
      },

      updateGroup: (group: Partial<ProjectGroup>) => {
        patchState(store, {
          users: getState(store).users!.map((user) => ({
            ...user,
            group: user.group.id === group.id ? { ...user.group, ...group } : user.group,
          })),
        });
      },

      uploadBulkInviteTemplate: rxMethod<File>(
        pipe(
          tap(() => patchState(store, { bulkInviteTemplateUploading: true })),
          switchMap((template) => {
            const cancel$ = new Subject<void>();
            const progress$ = new Subject<number>();
            const toastRef = toastService.progress(translateService.instant('common.Toaster.File_uploading') as string, {
              buttons: [{
                command: () => {
                  patchState(store, { bulkInviteTemplateUploading: false });
                  cancel$.next();
                },
                label: translateService.instant('common.BTN.cancel') as string,
              }],
              progress$,
              sticky: true,
            });

            return projectUsersService.uploadBulkInviteTemplate(projectStore.project()!, template)
              .pipe(
                tap((uploadEvent) => progress$.next(uploadEvent.progress)),
                skipWhile((uploadEvent) => !uploadEvent.response),
                tap(({ response }) => {
                  toastService.success(translateService.instant('common.Toaster.File_uploaded') as string);
                  patchState(store, {
                    bulkInviteTemplateUploading: false,
                    bulkInviteUsers: response!.body!.map((user) => ({ ...user, email: (user.email ?? '').toLowerCase(), id: uniqueId() })),
                  });
                }),
                catchError((error: HttpError) => {
                  patchState(store, { bulkInviteTemplateUploading: false });
                  errorsStore.requestError(error);

                  return VOID;
                }),
                takeUntil(cancel$),
                finalize(() => {
                  cancel$.complete();
                  progress$.complete();
                  toastRef?.close();
                }),
              );
          }),
        )
      ),
    };
  }),
);
