<p-confirmDialog
  acceptIcon="no-icon"
  appendTo="body"
  [header]="'Confirmation' | translate"
  icon=""
  rejectButtonStyleClass="p-button-outlined p-button-secondary"
  rejectIcon="no-icon"
></p-confirmDialog>

<p-confirmDialog
  acceptButtonStyleClass="p-button-danger"
  acceptIcon="no-icon"
  [header]="'Confirmation' | translate"
  icon=""
  [key]="keys.CONFIRM_NEGATIVE"
  rejectButtonStyleClass="p-button-outlined p-button-secondary"
  rejectIcon="no-icon"
></p-confirmDialog>
