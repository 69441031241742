import { Provider } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

class CustomUrlSerializer extends DefaultUrlSerializer {
  override parse(originalUrl: string): UrlTree {
    const [url, query] = originalUrl.split('?');
    let resultUrl = url.replace(/['":()<>%]/g, '-');

    if (query) {
      resultUrl += `?${query}`;
    }

    return super.parse(resultUrl);
  }
}

export function provideUrlSerializer(): Provider[] {
  return [{
    provide: UrlSerializer,
    useClass: CustomUrlSerializer,
  }];
}
