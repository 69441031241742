const KEYS_MAP: Record<string, string> = {
  ArrowDown: 'arrowDown',
  ArrowUp: 'arrowUp',
  Back: 'back',
  Backspace: 'backspace',
  Delete: 'delete',
  Digit0: '0',
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Enter: 'enter',
  Escape: 'escape',
  KeyA: 'a',
  KeyB: 'b',
  KeyC: 'c',
  KeyD: 'd',
  KeyE: 'e',
  KeyF: 'f',
  KeyG: 'g',
  KeyH: 'h',
  KeyI: 'i',
  KeyJ: 'j',
  KeyK: 'k',
  KeyL: 'l',
  KeyM: 'm',
  KeyN: 'n',
  KeyO: 'o',
  KeyP: 'p',
  KeyQ: 'q',
  KeyR: 'r',
  KeyS: 's',
  KeyT: 't',
  KeyU: 'u',
  KeyV: 'v',
  KeyW: 'w',
  KeyX: 'x',
  KeyY: 'y',
  KeyZ: 'z',
  Tab: 'tab',
};

export function formatEventToShortcut(event: KeyboardEvent): string {
  const modifiers: string[] = [
    ...event.metaKey ? ['cmd'] : [],
    ...event.ctrlKey ? ['ctrl'] : [],
    ...event.altKey ? ['alt'] : [],
    ...event.shiftKey ? ['shift'] : [],
  ];

  return [...modifiers, KEYS_MAP[event.code]].join('+');
}
