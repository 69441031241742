import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { HeapService } from './heap.service';

export function provideHeap(enabled: boolean, key: string): Provider[] {
  return enabled
    ? [
      {
        multi: true,
        provide: APP_INITIALIZER,
        useFactory: () => {
          const heapService = inject(HeapService);

          return () => {
            heapService.initialize(key);
          };
        },
      },
    ]
    : [];
}
