import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStoreState } from './router.reducer';

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStoreState> {
  serialize(routerState: RouterStateSnapshot): RouterStoreState {
    let route = routerState.root;
    let data = route.data;
    let params = route.params;

    while (route.firstChild) {
      route = route.firstChild;
      data = { ...data, ...route.data };
      params = { ...params, ...route.params };
    }

    const { url, root: { queryParams } } = routerState;

    return { data, params, queryParams, url };
  }
}
