import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';

declare global {
  interface Window {
    // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
    heap: Heap;
  }
}

export type HeapProperties = Record<string, string>;

/* eslint-disable custom-rules/prefer-interface-property-readonly */
interface Heap {
  addEventProperties: (props: HeapProperties) => void;
  addUserProperties: (props: HeapProperties) => void;
  appid: string;
  clearEventProperties: () => void;
  config: HeapConfig;
  identify: (id: string) => void;
  load: (heapKey: string, heapConfig: HeapConfig) => void;
  push: (...args: unknown[]) => void;
  removeEventProperty: (name: string) => void;

  // eslint-disable-next-line typescript-sort-keys/interface
  [K: string]: unknown;
}
/* eslint-enable custom-rules/prefer-interface-property-readonly */

interface HeapConfig {
  readonly disableTextCapture: boolean;
  readonly secureCookie: boolean;
}

const HEAP_EVENTS: string[] = [
  'addEventProperties',
  'addUserProperties',
  'clearEventProperties',
  'identify',
  'removeEventProperty',
  'resetIdentity',
  'setEventProperties',
  'track',
  'unsetEventProperty',
];

@Injectable({ providedIn: 'root' })
export class HeapService {
  readonly #document = inject(DOCUMENT);
  readonly #renderer2 = inject(RendererFactory2).createRenderer(null, null);

  #key?: string;

  addEventProperties(props: HeapProperties): void {
    if (this.#key) {
      window.heap.clearEventProperties();
      window.heap.addEventProperties(props);
    }
  }

  addUserProperties(props: HeapProperties): void {
    if (this.#key) {
      window.heap.addUserProperties(props);
    }
  }

  identify(userId: string): void {
    if (!this.#key) {
      return;
    }

    if (userId) {
      window.heap.identify(userId);
    } else {
      console.warn('HeapService.identify: userId is not defined');
    }
  }

  initialize(key: string): void {
    if (this.#key) {
      throw new Error('Heap is already initialized');
    }

    this.#key = key;

    window.heap = window.heap || [];
    window.heap.load = this.#loadHeap.bind(this) as Heap['load'];
    window.heap.load(key, {} as HeapConfig);
  }

  removeEventProperties(props: string[]): void {
    if (this.#key) {
      props.forEach((prop) => window.heap.removeEventProperty(prop));
    }
  }

  removeUserProperties(props: string[]): void {
    if (!this.#key) {
      return;
    }

    const properties: HeapProperties = {};

    props.forEach((prop) => {
      properties[prop] = '<No value>';
    });

    window.heap.addUserProperties(properties);
  }

  #createHeapEvents(): void {
    HEAP_EVENTS.forEach((heapEvent) => {
      window.heap[heapEvent] = (...args: unknown[]): void => {
        window.heap.push([
          heapEvent,
          args,
        ]);
      };
    });
  }

  #createHeapScript(): void {
    const script = this.#renderer2.createElement('script') as HTMLScriptElement;
    const scriptSrc = `https://cdn.heapanalytics.com/js/heap-${this.#key!}.js`;

    this.#renderer2.setAttribute(script, 'async', '');
    this.#renderer2.setAttribute(script, 'src', scriptSrc);
    this.#renderer2.appendChild(this.#document.head, script);
  }

  #loadHeap(heapKey: string, heapConfig: HeapConfig): void {
    window.heap.appid = heapKey;
    window.heap.config = heapConfig;

    this.#createHeapScript();
    this.#createHeapEvents();
  }
}
