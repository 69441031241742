import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { AuthService } from '@ideals/core/auth';
import { LocalStorageService } from '@ideals/services/local-storage';

import { HelpCenterService } from '../help-center';

export const COUNTRY_CODE_STORAGE_KEY = 'countryCode';
export const DEFAULT_COUNTRY_CODE = 'US';

@Injectable({ providedIn: 'root' })
export class CountryCodeService {
  readonly #authService = inject(AuthService);
  readonly #helpCenterService = inject(HelpCenterService);
  readonly #localStorageService = inject(LocalStorageService);

  getCountryCode$(): Observable<string> {
    const code = this.#localStorageService.getItem<string>(COUNTRY_CODE_STORAGE_KEY);

    return code ? of(code) : this.#resolveByLocation$();
  }

  saveCountryCode(code: string): void {
    this.#localStorageService.setItem(COUNTRY_CODE_STORAGE_KEY, code);
  }

  #resolveByLocation$(): Observable<string> {
    const codes$ = this.#helpCenterService.loadCountryCodes();
    const code = this.#authService.geolocation().countryCode;

    return codes$.pipe(map((codes) => codes[code] || DEFAULT_COUNTRY_CODE));
  }
}
