import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, merge, Observable } from 'rxjs';

import { EventBusService } from '@ideals/services/event-bus';

import { HttpError, HttpErrorForbidden, RequestError } from '../../models';

import { errorsActions } from './errors.actions';

@Injectable({ providedIn: 'root' })
export class ErrorsFacade {
  readonly #actions = inject(Actions);
  readonly #eventBusService = inject(EventBusService);
  readonly #store = inject(Store);

  get onForbiddenError$(): Observable<HttpErrorForbidden> {
    return merge(
      this.#actions.pipe(ofType(errorsActions.requestError)).pipe(
        filter(({ error }) => error.status === 'Forbidden' && !!(error as HttpErrorForbidden).error),
        map(({ error }) => error as HttpErrorForbidden),
      ),
      this.#eventBusService.on<HttpErrorForbidden>('errors:forbidden'),
    );
  }

  requestError(error: HttpError | RequestError): void {
    this.#store.dispatch(errorsActions.requestError({ error }));
  }
}
