export interface FieldValuesMap<T> {
  [ field: string ]: (value: unknown, item: T) => unknown;
}

interface VirtualItem {
  readonly [key: string]: VirtualItem;
}

type ItemFieldsCache = Map<string, unknown>;
export type ItemsCache<T> = Map<T, ItemFieldsCache>;

export function getItemFieldValue<T extends object>(
  item: T,
  field: string,
  fieldValuesMap?: FieldValuesMap<T>,
  cache?: ItemsCache<T>,
): unknown {
  let fieldsCache: ItemFieldsCache | null = null;

  if (cache) {
    fieldsCache = cache.get(item) ?? null;

    if (!fieldsCache) {
      fieldsCache = new Map();
      cache.set(item, fieldsCache);
    }

    if (fieldsCache.has(field)) {
      return fieldsCache.get(field)!;
    }
  }

  const fields = field.split('.').filter(Boolean);
  let value = fields.reduce((i: VirtualItem, f) => i?.[f], item as VirtualItem) as unknown;

  if (fieldValuesMap && field in fieldValuesMap) {
    value = fieldValuesMap[field](value, item);
  }

  if (fieldsCache) {
    fieldsCache.set(field, value);
  }

  return value;
}
