import { EnvironmentProviders } from '@angular/core';
import { NavigationActionTiming, provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';

import { CustomRouterStateSerializer } from './router';

import { rootStoreReducer } from './root-store.reducer';

export function provideRootStore(): EnvironmentProviders[] {
  return [
    provideStore(rootStoreReducer, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateImmutability: true,
        strictStateSerializability: false,
      },
    }),
    provideRouterStore({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: CustomRouterStateSerializer,
    }),
  ];
}
