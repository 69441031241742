<p-toast
  #toast
  [autoZIndex]="false"
  [style]="{'z-index': toastZIndex}"
>
  <ng-template let-message pTemplate="message">
    @if (asMessage(message); as message) {
      @if (message.icon === 'progress-circle') {
        <ideals-progress-circle [value]="(message.data?.progress$ | async) ?? 0"/>
      } @else {
        <span class="p-toast-message-icon" [ngClass]="message.icon"></span>
      }

      <div class="p-toast-message-text" fxFlex="grow">
        <div class="p-toast-detail ideals-text-wrap">{{ message.detail }}</div>

        @if (message.data?.buttons?.length) {
          <div class="mt-4 p-buttons-group">
            @for (button of message.data!.buttons!; track button; let i = $index) {
              <button
                class="p-button p-button-text"
                [class]="'toast-btn toast-btn-' + i"
                (click)="onButtonClick(message, button)"
              >
                <span
                  class="ideals-text-ellipsis"
                  [pTooltip]="button.label | translate"
                  [tooltipEllipsisOnly]="true"
                  tooltipPosition="bottom"
                  [tooltipZIndex]="tooltipZIndex"
                >
                  {{ button.label | translate }}
                </span>
              </button>
            }

            @for (button of message.data!.buttons!; track button; let i = $index) {
              @if (button.shortcut; as shortcut) {
                <div class="shortcut-wrapper" [class]="'shortcut-wrapper-' + i">
                  <span class="shortcut-label">
                    {{ getShortcutValue(shortcut) }}
                  </span>
                </div>
              }
            }
          </div>
        }
      </div>
    }
  </ng-template>
</p-toast>
