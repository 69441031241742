import { APP_BASE_HREF } from '@angular/common';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export function baseHrefInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const baseHref = inject(APP_BASE_HREF);
  const url = request.url;

  if (url.startsWith('http') || baseHref === '/') {
    return next(request);
  }

  return next(request.clone({
    url: url.startsWith('/') ? `${baseHref}${url}` : `${baseHref}/${url}`,
  }));
}
