<svg
  [attr.height]="size()"
  [attr.width]="size()"
  class="progress-circle"
  viewBox="2 -2 28 36"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    [attr.stroke-width]="strokeWidth()"
    class="progress-background"
    cx="16"
    cy="16"
    r="16"
  ></circle>

  <circle
    [attr.stroke-dashoffset]="strokeDashOffset()"
    [attr.stroke-width]="strokeWidth()"
    class="progress-line"
    cx="16"
    cy="16"
    r="16"
  ></circle>
</svg>
