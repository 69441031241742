import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';

import { AuthUserStore } from './auth-user';
import { ErrorsEffects, ErrorsStore } from './errors';
import { provideProjectStore } from './project';
import { provideProjectUsersStore } from './project-users';
import { provideProjectsStore } from './projects';

export function provideVdrStore(): (Provider | EnvironmentProviders)[] {
  return [
    AuthUserStore,
    ErrorsStore,
    provideEffects([
      ErrorsEffects,
    ]),
    // TODO: remove provideProjectStore, provideProjectsStore, provideProjectUsersStore after https://ideals.atlassian.net/browse/PHX-7982
    provideProjectStore(),
    provideProjectsStore(),
    provideProjectUsersStore(),
  ];
}
