import { AccessLevelToIrl } from './access-level-to-irl.model';
import { AccessLevelToPermissions } from './access-level-to-permissions.model';
import { AccessLevelToReports } from './access-level-to-reports.model';
import { AccessLevelToUsers } from './access-level-to-users.model';

export type FeaturePermissionId =
  | 'AccessLevelToIrl'
  | 'AccessLevelToPermissions'
  | 'AccessLevelToReports'
  | 'AccessLevelToUsers';

export type FeaturePermissionValue =
  | AccessLevelToIrl
  | AccessLevelToReports
  | AccessLevelToUsers
  | AccessLevelToPermissions;

export interface FeaturePermission {
  readonly hint?: string;
  readonly icon?: string;
  readonly id?: FeaturePermissionId;
  //  Needs only to detect item that should be hidden under ff. Remove after deletion of fvdr-irl-feature-permission-enabled
  readonly key?: FeaturePermissionId;
  readonly label: string;
  readonly value?: FeaturePermissionValue;
}

export const featurePermissionValuesById: Record<FeaturePermissionId, FeaturePermissionValue[]> = {
  AccessLevelToIrl: ['None', 'Manage'],
  AccessLevelToReports: ['None', 'ViewOnlyOwnReports', 'ViewReportsOfVisibleGroups'],
  AccessLevelToUsers: ['None', 'ViewOnlyOwnProfile', 'ViewVisibleGroups', 'ManageUsersOfVisibleGroups', 'ManageVisibleGroups'],
  AccessLevelToPermissions: ['None', 'ManagePermissionsOfVisibleGroups'],
};
