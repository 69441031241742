import { ActionReducerMap } from '@ngrx/store';

import { RouterState, routerReducer } from './router';

export interface RootStoreState {
  readonly router: RouterState;
}

export const rootStoreReducer: ActionReducerMap<RootStoreState> = {
  router: routerReducer,
};
