import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { finalize, Observable, share } from 'rxjs';

const REQUESTS = new Map<string, Observable<HttpEvent<unknown>>>();

export function exhaustInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (request.method !== 'GET') {
    return next(request);
  }

  const url = `${request.url}?${request.params.toString()}`;

  if (REQUESTS.has(url)) {
    return REQUESTS.get(url)!;
  }

  const next$ = next(request).pipe(
    share(),
    finalize(() => {
      REQUESTS.delete(url);
    }),
  );

  REQUESTS.set(url, next$);

  return next$;
}
