interface User {
  readonly email: string;
  readonly firstName: string;
  readonly lastName?: string;
}

export function getUserDisplayName(user: User, fallback = user.email): string {
  const name = `${user.firstName} ${user.lastName ?? ''}`;

  return name.trim().length > 0 ? name.trim() : fallback;
}
