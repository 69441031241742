import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { ClarityService } from './clarity.service';

export function provideClarity(enabled: boolean, key: string): Provider[] {
  return enabled
    ? [
      {
        multi: true,
        provide: APP_INITIALIZER,
        useFactory: () => {
          const clarityService = inject(ClarityService);

          return () => {
            clarityService.initialize(key);
          };
        },
      },
    ]
    : [];
}
