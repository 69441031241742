export function downloadFile(data: string | File | Blob | MediaSource, fileName: string): void {
  const link = document.createElement('a');
  const isPreparedUrl = typeof data === 'string';
  const href = isPreparedUrl ? data : URL.createObjectURL(data);

  link.setAttribute('download', fileName);
  link.href = href;
  link.setAttribute('target', '_blank');
  link.click();

  if (!isPreparedUrl) {
    URL.revokeObjectURL(href);
  }
}
