import { APP_BASE_HREF } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth';
import { normalizeUrl } from '../../utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ideals-auth-sign-in-container',
  standalone: true,
  template: '',
})
export class AuthSignInOidcContainer implements OnInit {
  readonly #appBaseHref = inject(APP_BASE_HREF);
  readonly #authService = inject(AuthService);
  readonly #router = inject(Router);

  ngOnInit(): void {
    if (!this.#authService.isLoggedIn) {
      this.#authService.initLogin(this.#appBaseHref);

      return;
    }

    const decodedState = decodeURIComponent(this.#authService.state) || this.#appBaseHref;
    const url = normalizeUrl(decodedState.replace(this.#appBaseHref, '/'));

    this.#navigateByURL(url);
  }

  #navigateByURL(url: string): void {
    this.#router.navigateByUrl(url, { replaceUrl: true });
  }
}
