import { HttpParams } from '@angular/common/http';

export function createHttpParams<T extends object>(params: T): HttpParams {
  let httpParams = new HttpParams();

  Object.keys(params).forEach((param) => {
    const value = params[param as keyof T];

    if (Array.isArray(value)) {
      (value as T[keyof T][]).forEach((v) => {
        httpParams = httpParams.append(param, v!.toString());
      });
    } else if (value != null && value !== '') {
      httpParams = httpParams.set(param, String(value));
    }
  });

  return httpParams;
}
