import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { FeatureFlagsConfig } from './feature-flags-config.model';
import { FeatureFlagsService } from './feature-flags.service';

export function provideFeatureFlags(config: FeatureFlagsConfig): Provider[] {
  return [
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => {
        const featureFlagsService = inject(FeatureFlagsService);

        return () => featureFlagsService.initialize(config);
      },
    },
  ];
}
