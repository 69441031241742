import { getCode } from './get-code.util';

function isMacOs(): boolean {
  return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
}

export function formatShortcutToEvent(shortcut: string): KeyboardEvent {
  const keys = shortcut.split('+');
  const event = {
    code: getCode(keys[keys.length - 1]),
    altKey: keys.includes('alt'),
    ctrlKey: keys.includes('ctrl') && !isMacOs(),
    metaKey: keys.includes('ctrl') && isMacOs() || keys.includes('cmd'),
    shiftKey: keys.includes('shift'),
  } as KeyboardEvent;
  let altIndex = 0;
  let shiftIndex = 0;

  if (new Set(keys).size !== keys.length) {
    throw new Error(`Shortcut contains duplication: ${shortcut}`);
  }

  if (event.ctrlKey) {
    altIndex++;
    shiftIndex++;

    if (keys.indexOf('ctrl') !== 0) {
      throw new Error('Incorrect "ctrl" order');
    }
  }

  if (event.altKey) {
    shiftIndex++;

    if (keys.indexOf('alt') !== altIndex) {
      throw new Error('Incorrect "alt" order');
    }
  }

  if (event.shiftKey && keys.indexOf('shift') !== shiftIndex) {
    throw new Error('Incorrect "shift" order');
  }

  return event;
}
