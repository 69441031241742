import { Routes } from '@angular/router';

import { AuthSignInOidcContainer, AuthSignOutOidcContainer } from './containers';
import { AuthRoute } from './models';

export const authRoutes: Routes = [
  {
    component: AuthSignInOidcContainer,
    path: AuthRoute.SignInOidc,
  },
  {
    component: AuthSignOutOidcContainer,
    path: AuthRoute.SignOutOidc,
  },
];
