import { Injectable } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';

interface EventBusMessage {
  readonly data?: unknown;
  readonly key: string;
}

@Injectable({ providedIn: 'root' })
export class EventBusService {
  readonly #eventBus$ = new Subject<EventBusMessage>();
  readonly #eventBusAsObservable$ = this.#eventBus$.asObservable();

  emit<T = unknown>(key: string, data?: T): void {
    this.#eventBus$.next({ key, data });
  }

  on<T = unknown>(key: string): Observable<T> {
    return this.#eventBusAsObservable$.pipe(
      filter((event) => event.key === key),
      map((event) => event.data as T)
    );
  }
}
