import { computed, Injectable, isDevMode, signal } from '@angular/core';
import { asapScheduler, catchError, firstValueFrom, from, map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client';

import { switchMapToVoid, VOID } from '@ideals/utils/void';

import { FeatureFlagsConfig } from './feature-flags-config.model';

const DEFAULT_CONTEXT = 'root';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  readonly #clients = new Map<string, { client: UnleashClient; client$: ReplaySubject<UnleashClient>; }>();
  readonly #initialized = signal(false);

  #config?: FeatureFlagsConfig;

  readonly initialized = computed(() => this.#initialized());

  initialize(config: FeatureFlagsConfig): Observable<void> {
    if (this.#config) {
      throw new Error('FeatureFlags service already initialized');
    }

    this.#config = config;

    return from(this.#getClient(DEFAULT_CONTEXT)).pipe(
      tap(() => this.#initialized.set(true)),
      switchMapToVoid,
      catchError(() => VOID),
    );
  }

  isEnabled(flag: string): boolean {
    return isDevMode() || (this.#clients.get(DEFAULT_CONTEXT)?.client?.isEnabled(flag) ?? false);
  }

  isEnabled$(flag: string, context?: string): Observable<boolean> {
    if (!context) {
      return of(this.isEnabled(flag));
    }

    return from(this.#getClient(this.normalizeContext(context))).pipe(
      map((client) => isDevMode() || client.isEnabled(flag)),
      catchError(() => of(false)),
    );
  }

  protected normalizeContext(context: string): string {
    const [prefix, domain] = context.split('@');

    return domain ? [prefix.split('+').pop(), domain].join('@') : context;
  }

  async #getClient(context: string): Promise<UnleashClient> {
    if (!this.#config) {
      return Promise.reject();
    }

    const { client$ } = this.#clients.get(context) ?? {};

    if (client$) {
      return firstValueFrom(client$);
    }

    const newClient = new UnleashClient({
      appName: this.#config!.name,
      clientKey: this.#config!.key,
      disableMetrics: true,
      disableRefresh: true,
      storageProvider: new InMemoryStorageProvider(),
      url: this.#config!.url,
    });
    const newClient$ = new ReplaySubject<UnleashClient>(1);

    this.#clients.set(context, { client: newClient, client$: newClient$ });

    if (context !== DEFAULT_CONTEXT) {
      await newClient.updateContext({ userId: context });
    }

    await newClient.start();

    asapScheduler.schedule(() => newClient$.next(newClient), 0);

    return newClient;
  }
}
