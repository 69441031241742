import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { HELP_CENTER_API_URL } from '../../help-center';
import { Article, Country, SearchArticle, Video, VideoTutorial } from '../../models';

@Injectable({ providedIn: 'root' })
export class HelpCenterService {
  readonly #apiUrl = inject(HELP_CENTER_API_URL);
  readonly #httpClient = inject(HttpClient);
  readonly #location: Location = window.location;

  loadAllArticlesFile(lang: string): Observable<Blob> {
    return this.#httpClient.get(`${this.#apiUrl}download/${lang}/help.html`, { responseType: 'blob' });
  }

  loadArticles(lang: string): Observable<Article[]> {
    return this.#httpClient.get<{ content: Article[]; }>(`${this.#apiUrl}content/${lang}/content.json`)
      .pipe(map(({ content }) => content));
  }

  loadArticlesIndex(lang: string): Observable<SearchArticle[]> {
    return this.#httpClient.get<SearchArticle[]>(`${this.#apiUrl}indexes/${lang}/index.json`);
  }

  loadCountries(lang: string): Observable<Country[]> {
    return this.#httpClient.get<{ countries: Country[]; }>(`${this.#apiUrl}countries/${lang}/content.json`)
      .pipe(map(({ countries }) => countries));
  }

  loadCountryCodes(): Observable<Record<string, string>> {
    return this.#httpClient.get<Record<string, string>>(`${this.#apiUrl}countries/map-phones.json`);
  }

  loadTutorialVideos(): Observable<VideoTutorial[]> {
    return this.#httpClient.get<{ videos: VideoTutorial[]; }>(`${this.#apiUrl}videos/tutorials/videos.json`)
      .pipe(map(({ videos }) => videos));
  }

  loadVideos(): Observable<Video[]> {
    return this.#httpClient.get<{ videos: Video[]; }>(`${this.#apiUrl}videos/videos.json`)
      .pipe(map(({ videos }) => videos));
  }

  logArticlesSearch(searchQueue: string, countSearchResults: number): Observable<void> {
    return this.#httpClient.post<void>('/api/stats/add', {
      searchQueue,
      countSearchResults,
      url: this.#location.href,
    });
  }
}
