import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { environment } from './environments/environment';

import { appConfig } from './app.config';
import { RootContainer } from './root.container';

import './instrument';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootContainer, appConfig).catch((error) => console.error(error));
