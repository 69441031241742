import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

const MAX_PERCENT_VALUE = 100;
const SIZE = '1.3rem';
const STROKE_WIDTH = '0.25rem';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    fxLayout: 'row',
  },
  selector: 'ideals-progress-circle',
  standalone: true,
  styleUrl: './progress-circle.component.scss',
  templateUrl: './progress-circle.component.html',
})
export class ProgressCircleComponent {
  readonly size = input(SIZE);
  readonly strokeWidth = input(STROKE_WIDTH);
  readonly value = input<number>();

  protected strokeDashOffset = computed(() => MAX_PERCENT_VALUE - (this.value() ?? 0));
}
