import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { AuthService } from '@ideals/core/auth';
import { Culture, LOCALES } from '@ideals/core/localization';

const MILLISECONDS_IN_MINUTE = 60000;
const MINUTES_IN_HOUR = 60;
const TEN = 10;

const UTC = 'Etc/UTC';

type DateValue = Date | string | number;

@Injectable({ providedIn: 'root' })
export class TimeZoneService {
  readonly #authService = inject(AuthService);
  readonly #httpClient = inject(HttpClient);
  readonly #translateService = inject(TranslateService);

  #userTimeOffsetInAngularFormat?: string;
  #userTimeZone?: string;

  get userTimeOffsetInAngularFormat(): string | undefined {
    if (this.#authService.user.isInternalLogin) {
      return '+0000';
    }

    return this.#userTimeOffsetInAngularFormat;
  }

  get userTimeZone(): string {
    if (this.#authService.user.isInternalLogin) {
      return UTC;
    }

    return this.#userTimeZone!;
  }

  getBrowserTimeZone(): string {
    const browserTimeZone = this.#getBrowserUnmappedTimeZone();

    // not all browsers renamed timezones
    const timeZoneNamesMap = new Map([
      ['Europe/Kyiv', 'Europe/Kiev'],
    ]);

    return timeZoneNamesMap.has(browserTimeZone) ? timeZoneNamesMap.get(browserTimeZone) as string : browserTimeZone;
  }

  getFormattedZonedDate(date: DateValue, formatPattern: string): string {
    return format(
      utcToZonedTime(date, this.userTimeZone),
      formatPattern,
      { locale: LOCALES[this.#translateService.currentLang as Culture] },
    );
  }

  getUTCDate(date: DateValue): Date {
    return zonedTimeToUtc(date, this.userTimeZone);
  }

  getZonedDate(date: DateValue): Date {
    return utcToZonedTime(date, this.userTimeZone);
  }

  isFuture(date: Date): boolean {
    const currentDate = this.getZonedDate(new Date());

    return date.getTime() > currentDate.getTime();
  }

  updateTimezoneIfNeeded(timeZone: string, isTimeZoneAutoUpdated: boolean): void {
    const browserTimeZone = this.getBrowserTimeZone();

    if (isTimeZoneAutoUpdated && timeZone !== browserTimeZone) {
      this.#httpClient.put('/api/profile/time-zone', { timeZone: browserTimeZone }).subscribe();
      this.#userTimeZone = browserTimeZone;
    } else {
      this.#userTimeZone = timeZone;
    }

    if (!isTimeZoneAutoUpdated) {
      this.#calculateOffsetInAngularFormat(timeZone);
    }
  }

  #calculateOffsetInAngularFormat(timeZone: string): void {
    const minutesOffsetInTimezone = getTimezoneOffset(timeZone) / MILLISECONDS_IN_MINUTE;

    const hours = Math.floor(minutesOffsetInTimezone / MINUTES_IN_HOUR);
    const minutes = Math.abs(minutesOffsetInTimezone % MINUTES_IN_HOUR);

    const absoluteHours = Math.abs(hours);
    const formattedHours = absoluteHours < TEN ? `0${absoluteHours}` : absoluteHours;
    const formattedMinutes = minutes < TEN ? `0${minutes}` : minutes;

    this.#userTimeOffsetInAngularFormat = `${hours >= 0 ? '+' : '-'}${formattedHours}${formattedMinutes}`;
  }

  #getBrowserUnmappedTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
