import { Culture, CultureItem } from './../../models';

export const CULTURE_LANGUAGES: Record<Culture, string> = {
  [Culture.US]: 'English',
  [Culture.CN]: '中文',
  [Culture.DE]: 'Deutsch',
  [Culture.SE]: 'Svenska',
  [Culture.FR]: 'Français',
  [Culture.ES]: 'Español',
  [Culture.BR]: 'Português',
  [Culture.IT]: 'Italiano',
  [Culture.NL]: 'Nederlands',
  [Culture.JP]: '日本語',
  [Culture.KR]: '한국어',
  [Culture.TR]: 'Türkçe',
  [Culture.PL]: 'Polski',
  [Culture.RU]: 'Русский',
  [Culture.UA]: 'Українська',
};

export const CULTURE_COUNTRIES: Record<Culture, string> = {
  [Culture.US]: 'United States',
  [Culture.CN]: '中国',
  [Culture.DE]: 'Deutschland',
  [Culture.SE]: 'Sverige',
  [Culture.FR]: 'France',
  [Culture.ES]: 'España, Alfabetización Internacional',
  [Culture.BR]: 'Brasil',
  [Culture.IT]: 'Italia',
  [Culture.NL]: 'Nederland',
  [Culture.JP]: '日本',
  [Culture.KR]: '대한민국',
  [Culture.TR]: 'Türkiye',
  [Culture.PL]: 'Polska',
  [Culture.RU]: 'Россия',
  [Culture.UA]: 'Україна',
};

export const CULTURES_ORDER: Culture[] = [
  Culture.US,
  Culture.CN,
  Culture.DE,
  Culture.SE,
  Culture.FR,
  Culture.ES,
  Culture.BR,
  Culture.IT,
  Culture.NL,
  Culture.JP,
  Culture.KR,
  Culture.TR,
  Culture.PL,
  Culture.RU,
  Culture.UA,
];

export const CULTURES: CultureItem[] = CULTURES_ORDER
  .map((id) => ({ id, caption: `${CULTURE_LANGUAGES[id]} (${CULTURE_COUNTRIES[id]})` }));

export const CULTURES_WITH_LANGUAGES: CultureItem[] = CULTURES_ORDER
  .map((id) => ({ id, caption: `${CULTURE_LANGUAGES[id]}` }));

export function getCulture(id: Culture): CultureItem | undefined {
  return CULTURES.find((c) => c.id === id);
}
