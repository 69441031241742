import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { DialogConfigKey } from '@ideals/models';

import { ConfirmDialogService } from './confirm-dialog.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConfirmDialogModule,
    TranslateModule,
  ],
  providers: [ConfirmationService],
  selector: 'ideals-confirmation-dialog',
  standalone: true,
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
  readonly #confirmationService = inject(ConfirmationService);
  readonly #confirmDialogService = inject(ConfirmDialogService);

  protected readonly keys: Record<DialogConfigKey, DialogConfigKey> = {
    CONFIRM_NEGATIVE: 'CONFIRM_NEGATIVE',
  };

  ngOnInit(): void {
    this.#confirmDialogService.setConfirmationService(this.#confirmationService);
  }
}
