import { Provider } from '@angular/core';

import { COVIEW_KEY_TOKEN } from './coview-key.token';

export function provideIntercom(enabled: boolean, key: string): Provider[] {
  return enabled
    ? [
      {
        provide: COVIEW_KEY_TOKEN,
        useValue: key,
      },
    ]
    : [];
}
