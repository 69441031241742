import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { createHttpParams } from '@ideals/utils/create-http-params';

import {
  DetailedProject,
  DocumentPermission,
  DocumentPermissionDetail,
  DocumentPermissionItems,
  ProjectBase,
  QnaDetailedThread,
  QnaListThread,
  QnaQuestionTeam,
  QnaSettings,
  QnaThreadPriority,
  QnaThreadsFilter,
} from '../../models';
import { getProjectsUrl } from '../../utils/get-projects-url';

interface QnaListDataDto {
  readonly threadIds: string[];
  readonly threads: QnaListThread[];
  readonly total: number;
}

export interface QnaPermissionItem {
  readonly permissionLevel: DocumentPermission;
  readonly permissionLevelDetails: DocumentPermissionDetail;
}

interface QnaPermissionsDto {
  permissions: Record<string, QnaPermissionItem>;
}

@Injectable({ providedIn: 'root' })
export class QnaService {
  readonly #httpClient = inject(HttpClient);

  approveAnswer(project: DetailedProject, threadId: string, targetPostId: string): Observable<void> {
    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads/approve`), {
      threadId,
      targetPostId,
    });
  }

  assignExperts(project: DetailedProject, threadId: string, expertsIds: number[]): Observable<QnaDetailedThread> {
    return this.#httpClient.put<QnaDetailedThread>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads/assign`), {
      questionId: threadId,
      expertUserIds: expertsIds,
    });
  }

  changeThreadPriority(project: DetailedProject, threadId: string, priority: QnaThreadPriority): Observable<void> {
    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads/${threadId}/priority`), {
      priority,
    });
  }

  closeThreads(project: DetailedProject, threadIds: string[]): Observable<void> {
    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads/close`), {
      threadIds,
    });
  }

  deleteThreads(project: DetailedProject, threadIds: string[]): Observable<void> {
    return this.#httpClient.delete<void>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads`), {
      params: { threadIds },
    });
  }

  loadQnaAnswerSidePermissions(project: DetailedProject): Observable<QnaPermissionsDto> {
    return this.#httpClient.get<QnaPermissionsDto>(getProjectsUrl(project.hostId!, `/${project.id}/qna/permissions/answer-side`)).pipe(
      map((permissions) => ({
        permissions: {
          ...permissions.permissions,
          AnswerApprover: permissions.permissions['answerApprover'],
          AnswerCoordinator: permissions.permissions['answerCoordinator'],
          Expert: permissions.permissions['expert'],
        },
      }))
    );
  }

  loadQnaDetailedThread(project: DetailedProject, threadId: string): Observable<QnaDetailedThread> {
    return this.#httpClient.get<QnaDetailedThread>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads/${threadId}`));
  }

  loadQnaQuestionSidePermissions(project: DetailedProject): Observable<QnaPermissionsDto> {
    return this.#httpClient.get<QnaPermissionsDto>(getProjectsUrl(project.hostId!, `/${project.id}/qna/permissions/question-side`));
  }

  loadQnaQuestionTeams(project: ProjectBase, groupId?: string): Observable<QnaQuestionTeam[]> {
    const params = createHttpParams({ ...groupId && { userGroupId: groupId } });

    return this.#httpClient.get<QnaQuestionTeam[]>(getProjectsUrl(project.hostId!, `/${project.id}/qna/teams`), { params });
  }

  loadQnaSettings(project: DetailedProject): Observable<QnaSettings> {
    return this.#httpClient.get<QnaSettings>(getProjectsUrl(project.hostId!, `/${project.id}/qna/settings`));
  }

  loadQnaThreads(project: DetailedProject, filter: QnaThreadsFilter): Observable<QnaListDataDto> {
    const params = createHttpParams<QnaThreadsFilter>(filter);

    return this.#httpClient.get<QnaListDataDto>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads`), { params });
  }

  submitThreads(project: DetailedProject, threadIds: string[]): Observable<void> {
    return this.#httpClient.put<void>(getProjectsUrl(project.hostId!, `/${project.id}/qna/threads/submit`), {
      threadIds,
    });
  }

  updateQnaAnswerSidePermissions(project: DetailedProject, permissions: DocumentPermissionItems): Observable<void> {
    const permissionsByTeams: Record<string, QnaPermissionItem> = {};

    for (const teamId in permissions) {
      const id = teamId[0].toLowerCase() + teamId.slice(1);

      permissionsByTeams[id] = {
        permissionLevel: permissions[teamId].permission,
        permissionLevelDetails: permissions[teamId].permissionDetails,
      };
    }

    return this.#httpClient.put<void>(
      getProjectsUrl(project.hostId!, `/${project.id}/qna/permissions/answer-side`),
      { permissions: permissionsByTeams },
    );
  }

  updateQnaQuestionSidePermissions(project: DetailedProject, permissions: DocumentPermissionItems): Observable<void> {
    const permissionsByTeams: Record<string, QnaPermissionItem> = {};

    for (const teamId in permissions) {
      permissionsByTeams[teamId] = {
        permissionLevel: permissions[teamId].permission,
        permissionLevelDetails: permissions[teamId].permissionDetails,
      };
    }

    return this.#httpClient.put<void>(
      getProjectsUrl(project.hostId!, `/${project.id}/qna/permissions/question-side`),
      { permissions: permissionsByTeams },
    );
  }
}
